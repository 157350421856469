import React from 'react';
import PropTypes from 'prop-types';
import isEqual from 'react-fast-compare';
import moment from 'moment';
import {
    faPlay,
    faPause,
    faBackward,
    faForward,
    faUndo,
    faVolumeUp,
    faVolumeMute,
    faDownload,
    faUpload
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import TimeBox from './TimeBox.js';

import style from './index.module.scss';
import {
    Button,
    Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle,
    Fade,
    Grid,
    IconButton, Menu, MenuItem,
    Tooltip,
    withStyles
} from "@material-ui/core";
import JSZip from "jszip";
import {getBinaryContent} from "jszip-utils";
import { saveAs } from "file-saver";
import SttAdapter from "../../../util/Adapters/SttJsonAdapter";
import {apiProvider} from "../../../services/api/utilities/Provider";
import ApiConversations from "../../../services/api/ApiConversations";
import { jsonEdit } from "../../../util/FileDownload";
import exportAdapter from "../../ExportAdapters";
import {CompositeDecorator, convertFromRaw, convertToRaw, EditorState} from "draft-js";
import Word from "../../TimedConversation/Word";
import {DropzoneDialog} from "material-ui-dropzone";
import { withAlert } from 'react-alert'

const styles = () => ({
    playerButton: {
        color: "white",
        backgroundColor: "#1E90FF", // you can add your specific CSS here.
        "&:hover, &.Mui-focusVisible": { backgroundColor: "grey" }
    },
    MenuItem: {
        justifyContent: "center"
    }
});

class PlayerControls extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            openUpload: false,
            upload: false,
            confirmDialog: { isOpen: false, title: '', content: '' }
        };

    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (!isEqual(this.state, nextState)) return true;

        return !isEqual(this.props, nextProps);
    }

    setIntervalHelperBackward = () => {
        // this.props.skipBackward();
        this.interval = setInterval(() => {
            this.props.skipBackward();
        }, 300);
    }

    setIntervalHelperForward = () => {
        // this.props.skipForward();
        this.interval = setInterval(() => {
            this.props.skipForward();
        }, 300);
    }

    clearIntervalHelper = () => {
        clearInterval(this.interval);
    }

    handleClose = () => {
        this.setState({anchorEl: null});
    };

    handleUpload = (file, id) => {
        this.setState({confirmDialog : {
            isOpen: false
        }})
        const alert = this.props.alert;
        let bodyFormData = new FormData();
        bodyFormData.append('transcript', file);
        ApiConversations.upload(bodyFormData, id, 'transcript').then(
            res => {
                if (res.message === "success"){
                    ApiConversations.complete(id).then(
                        res => {
                            if (res._id){
                                alert.success("Changed status to done")
                                console.log("Changed status to done")
                            }
                            this.props.upload(true);
                            alert.success("Uploaded and changed the transcript successfully")
                            console.log("Uploaded and changed the transcript successfully")
                        }
                    )
                }
                else{
                    alert.error("Transcript Upload Failed")
                }
            }
        )
    }

    handleTextGridDownload = (name, id, createdAt) => {
        const alert = this.props.alert;
        const textGridAlert = alert.info("Downloading TextGrid conversation",{timeout:600000});
        this.handleClose();
        ApiConversations.download(id).then(
            res => {
                saveAs(res, `${name}-${id}-${moment(createdAt).format('DDMMYYYY')}.zip`)
                alert.remove(textGridAlert);
            }
        )
    }

    handleDownloadAudio = (title, id) => {
        const alert = this.props.alert;
        this.handleClose();
        let tmpData;
        console.log("Download All");
        apiProvider.auth(sessionStorage.getItem("accessToken"));
        const normalAlert = alert.info("Downloading normal conversation",{timeout:600000});
        ApiConversations.getSingle(id).then(
            res => {
                getBinaryContent(res.finalAudio.cloudLink, function (err, data) {
                    if (err) {
                        console.log(err); // or handle the error
                    }
                    let zip = new JSZip();
                    let folder = zip.folder(id);
                    folder.file(`${title}-${id}.wav`, data, {binary: true});

                    tmpData = jsonEdit(res);
                    folder.file(`${title}-${id}.json`, tmpData)

                    const blocks = SttAdapter(res);
                    let contentState = convertFromRaw(blocks);
                    // eslint-disable-next-line no-use-before-define
                    let editorState = EditorState.createWithContent(contentState, decorator);

                    const txtData = exportAdapter(
                        convertToRaw(editorState.getCurrentContent()),
                        'txt'
                    );
                    folder.file(`${title}-${id}.txt`, txtData.data)

                    zip.generateAsync({type: "blob"}).then(function (blob) {
                        saveAs(blob, `${title}-${id}.zip`);
                        alert.remove(normalAlert);
                    }, function (err) {
                        console.log(err);
                    });
                });
            }
        );
    }

    handleClick = (event) => {
        this.setState({anchorEl: event.currentTarget});
    };

    confirmationDialog = () => {

        return (
            <Dialog open={this.state.confirmDialog.isOpen}>
                <DialogTitle>
                    {this.state.confirmDialog.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {this.state.confirmDialog.content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        color="default"
                        onClick={() => {
                                this.setState({ confirmDialog: { isOpen: false }});
                                this.setState({ openUpload: true });
                            }
                        }
                    >
                        Cancel
                    </Button>
                    <Button
                        color="secondary"
                        onClick={this.state.confirmDialog.onConfirm}
                    >
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={ style.playerControls }>
                <TimeBox
                    promptSetCurrentTime={ this.props.promptSetCurrentTime }
                    currentTime={ this.props.currentTime }
                    duration={ this.props.duration }
                />

                <Grid container justify="center" spacing={2}>
                    <Grid key={0} item xs={2} sm={'auto'}>
                        <Tooltip title="Replay" aria-label="Replay">
                            <IconButton className={ classes.playerButton }
                                        onClick={ this.props.rollback }
                                        aria-label="Replay">
                                <FontAwesomeIcon icon={ faUndo } />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid key={1} item xs={2} sm={'auto'}>
                        <Tooltip title="Seek backward" aria-label="Seek backward">
                            <IconButton className={ classes.playerButton }
                                        aria-label="Seek backward"
                                        onMouseDown={ this.setIntervalHelperBackward }
                                        onMouseUp={ this.clearIntervalHelper }
                                        onClick={ this.props.skipBackward }>
                                <FontAwesomeIcon icon={ faBackward } />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid key={2} item xs={2} sm={'auto'}>
                        <Tooltip title="Play/Pause" aria-label="Play/Pause">
                            <IconButton className={ classes.playerButton }
                                        aria-label="Play/Pause"
                                        onClick={ this.props.playMedia }>
                                {this.props.isPlaying ? <FontAwesomeIcon icon={ faPause } /> : <FontAwesomeIcon icon={ faPlay } />}
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid key={3} item xs={2} sm={'auto'}>
                        <Tooltip title="Seek forward" aria-label="Seek forward">
                            <IconButton className={ classes.playerButton }
                                        aria-label="Seek forward"
                                        onMouseDown={ this.setIntervalHelperForward }
                                        onMouseUp={ this.clearIntervalHelper }
                                        onClick={ this.props.skipForward }>
                                <FontAwesomeIcon icon={ faForward } />
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid key={4} item xs={2} sm={'auto'}>
                        <Tooltip title="Toggle sound" aria-label="Toggle sound">
                            <IconButton className={ classes.playerButton }
                                        aria-label="Toggle sound"
                                        onClick={ this.props.handleMuteVolume }>
                                { this.props.isMute ? <FontAwesomeIcon icon={ faVolumeMute } /> : <FontAwesomeIcon icon={ faVolumeUp } /> }
                            </IconButton>
                        </Tooltip>
                    </Grid>

                    <Grid key={5} item xs={2} sm={'auto'}>
                        <Tooltip title="Download audio & transcript" aria-label="Download audio & transcript">
                            <IconButton className={ classes.playerButton }
                                        aria-label="Download audio"
                                        onClick={ this.handleClick }>
                                <FontAwesomeIcon icon={ faDownload } />
                            </IconButton>
                        </Tooltip>
                        <Menu
                            id="download-menu"
                            anchorEl={this.state.anchorEl}
                            open={Boolean(this.state.anchorEl)}
                            keepMounted
                            onClose={this.handleClose}
                            TransitionComponent={Fade}
                            elevation={10}
                            getContentAnchorEl={null}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <MenuItem className={ classes.MenuItem } onClick={() => this.handleDownloadAudio(this.props.title, this.props.fileName)}>Normal</MenuItem>
                            <MenuItem className={ classes.MenuItem } onClick={() => this.handleTextGridDownload(this.props.title, this.props.fileName, this.props.createdAt)}>TextGrid & Audio</MenuItem>
                        </Menu>
                    </Grid>
                    { sessionStorage.getItem('role') === "group-admin" && this.props.language === 'manual-english' ?
                        <Grid key={6} item xs={2} sm={'auto'}>
                            <Tooltip title="Upload TextGrid" aria-label="Upload TextGrid">
                                <IconButton className={ classes.playerButton }
                                            aria-label="Upload transcript"
                                            onClick={() => this.setState({openUpload: true})}>
                                    <FontAwesomeIcon icon={ faUpload } />
                                </IconButton>
                            </Tooltip>
                            <DropzoneDialog
                                acceptedFiles={['.TextGrid']}
                                cancelButtonText={"Cancel"}
                                submitButtonText={"Submit"}
                                filesLimit={1}
                                open={this.state.openUpload}
                                onClose={() => this.setState({openUpload: false})}
                                onSave={(files) => {
                                    this.setState({
                                        confirmDialog : {
                                            isOpen: true,
                                            title: 'SUBMIT',
                                            content: "Are you sure you want to upload this TextGrid file?",
                                            onConfirm: () => { this.handleUpload(files[0], this.props.fileName); }
                                        }
                                    })
                                    this.setState({openUpload: false});
                                }}
                                showPreviews={true}
                                showFileNamesInPreview={true}
                            />
                        </Grid> : null
                    }
                </Grid>
                {this.props.exportOptions}
                {this.confirmationDialog()}
            </div>
        );
    }
}

const getEntityStrategy = mutability => (
    contentBlock,
    callback,
    contentState
) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
            return false;
        }

        return contentState.getEntity(entityKey).getMutability() === mutability;
    }, callback);
};

const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy('MUTABLE'),
        component: Word
    }
]);

PlayerControls.propTypes = {
    title: PropTypes.string,
    playMedia: PropTypes.func,
    currentTime: PropTypes.string,
    timecodeOffset: PropTypes.string,
    promptSetCurrentTime: PropTypes.func,
    rollback: PropTypes.func,
    handleMuteVolume: PropTypes.func,
    duration: PropTypes.string,
    isPlaying: PropTypes.bool,
    isMute: PropTypes.bool,
    skipBackward: PropTypes.func,
    skipForward: PropTypes.func,
    mediaUrl: PropTypes.string,
    fileName: PropTypes.string,
    createdAt: PropTypes.string,
    language: PropTypes.string,
    upload: PropTypes.func
};

export default withStyles(styles)(withAlert()(PlayerControls));