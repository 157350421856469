import React from "react";
import PropTypes from "prop-types";
import TimedTextEditor from "../TimedConversation";
import MediaPlayer from "../MediaPlayer";
import style from "./index.module.css";
import Header from "./Header";
import AudioPlayer from "../TimedConversation/AudioPlayer";
import {secondsToTimeCode} from "../../util/TimeCodeConverter";
import ExportFormatSelect from "./ExportFormatSelect";
import {apiProvider} from "../../services/api/utilities/Provider";
import ApiConversations from "../../services/api/ApiConversations";
import {Grid} from "@material-ui/core";
import {fileDownload, jsonEdit} from "../../util/FileDownload";
import {connect} from "react-redux";

class TranscriptDisplay extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();

        this.state = {
            currentTime: 0,
            transcriptData: null,
            isScrollIntoViewOn: true,
            timecodeOffset: 0,
            showTimecodes: true,
            showSpeakers: true,
            mediaDuration: "00:00:00:00",
            exportFormat: "",
            upload: false,
        };

        this.timedTextEditorRef = React.createRef();
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.transcriptData !== null) {
            return {
                transcriptData: nextProps.transcriptData
            };
        }

        return null;
    }

    // performance optimization
    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.mediaUrl !== this.props.mediaUrl) {
            return true;
        }

        if (nextProps.transcriptData !== this.props.transcriptData) {
            return true;
        }

        return nextState !== this.state;
    };

    componentDidMount() {
        this.props.resetTimestamp();
    }

    // eslint-disable-next-line class-methods-use-this
    handleWordClick = startTime => {
        this.setCurrentTime(startTime);
    };

    // eslint-disable-next-line class-methods-use-this
    handleTimeUpdate = e => {
        const currentTime = e.target.currentTime;
        this.setState({
            currentTime
        });
    };

    handlePlayMedia = isPlaying => {
        this.playMedia(isPlaying);
    };

    handleIsPlaying = () => {
        return this.isPlaying();
    };

    handleIsScrollIntoViewChange = e => {
        const isChecked = e.target.checked;
        this.setState({ isScrollIntoViewOn: isChecked });
    };

    handleSetTimecodeOffset = timecodeOffset => {
        this.setState({ timecodeOffset: timecodeOffset }, () => {
            this.timedTextEditorRef.current.forceUpdate();
        });
    };

    handleShowTimecodes = e => {
        const isChecked = e.target.checked;
        this.setState({ showTimecodes: isChecked });
    };

    handleShowSpeakers = e => {
        const isChecked = e.target.checked;
        this.setState({ showSpeakers: isChecked });
    };

    handleExportFormatChange = event => {
        console.log(event.target.name, event.target.value);
        this.setState({ exportFormat: event.target.value });
    };

    getEditorContent = exportFormat => {
        const title = this.props.title ? this.props.title : "";

        return this.timedTextEditorRef.current !== null ? this.timedTextEditorRef.current.getEditorContent(
            exportFormat,
            title
        ) : { data: '', ext: exportFormat };
    };

    exportTranscript = () => {
        console.log("export");

        let tmpData;

        console.log(this.state.exportFormat)

        if (this.state.exportFormat === "json"){
            apiProvider.auth(sessionStorage.getItem("accessToken"));
            ApiConversations.getSingle(this.props.fileName).then(
                res => {
                    tmpData = jsonEdit(res);
                    fileDownload(tmpData, `${this.props.title}-${this.props.fileName}.json`);
                }
            );
        }
        else if(this.state.exportFormat !== null){
            const { data, ext } = this.getEditorContent(
                this.state.exportFormat
            );

            tmpData = data;
            fileDownload(tmpData, `${this.props.title}-${this.props.fileName}.${ext}`);
        }
    };

    onLoadedDataGetDuration = e => {
        const currentDuration = e.target.duration;
        const currentDurationWithOffset =
            currentDuration + this.state.timecodeOffset;
        const durationInSeconds = secondsToTimeCode(currentDurationWithOffset);

        this.setState({
            mediaDuration: durationInSeconds
        });
    };

    /*
    handleDownloadAll = () => {
        let tmpData;

        if (this.props.fileName !== null && this.props.title !== null){
            console.log("Download All")
            const fileName = this.props.fileName;
            const title = this.props.title;
            const editorContent = this.getEditorContent(
                'txt'
            );
            apiProvider.auth(sessionStorage.getItem("accessToken"));
            ApiConversations.getSingle(this.props.fileName).then(
                res => {
                    getBinaryContent(res.finalAudio.cloudLink, function (err, resData) {
                        if (err) {
                            console.log(err); // or handle the error
                        }
                        let zip = new JSZip();
                        let folder = zip.folder(fileName);
                        folder.file(`${title}-${fileName}.wav`, resData, {binary: true});

                        tmpData = jsonEdit(res);
                        folder.file(`${title}-${fileName}.json`, tmpData)

                        const { data, ext } = editorContent;

                        folder.file(`${title}-${fileName}.${ext}`, data)

                        zip.generateAsync({type: "blob"}).then(function (blob) {
                            saveAs(blob, `${title}-${fileName}.zip`);
                        }, function (err) {
                            console.log(err);
                        });
                    });
                }
            );
        }
    }
    */


    render() {
        const audioPlayer = (
            <AudioPlayer
                mediaUrl={this.props.mediaUrl}
                onTimeUpdate={this.handleTimeUpdate}
                videoRef={this.videoRef}
                onLoadedDataGetDuration={this.onLoadedDataGetDuration}
            />
        );

        const exportOptions = (
            <div>
                <Grid container direction={"row"} spacing={5} justify={"center"} alignItems={"center"}>
                    <Grid item>
                        <Grid key={1} item>
                            <ExportFormatSelect
                                name={"exportFormat"}
                                value={this.state.exportFormat}
                                handleChange={this.handleExportFormatChange}
                            />
                        </Grid>
                        <Grid key={2} item>
                            <button className={style.demoNavItem} onClick={this.exportTranscript}>
                                Export Transcript
                            </button>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );

        const mediaControls = (
            <MediaPlayer
                title={this.props.title ? this.props.title : ""}
                mediaDuration={this.state.mediaDuration}
                currentTime={this.state.currentTime}
                hookSeek={foo => (this.setCurrentTime = foo)}
                hookPlayMedia={foo => (this.playMedia = foo)}
                hookIsPlaying={foo => (this.isPlaying = foo)}
                timecodeOffset={this.state.timecodeOffset}
                mediaUrl={this.props.mediaUrl}
                videoRef={this.videoRef}
                fileName={this.props.fileName}
                createdAt={this.props.createdAt}
                language={this.props.language}
                exportOptions={exportOptions}
                upload={this.props.upload}
                isScrollIntoViewOn={this.state.isScrollIntoViewOn}
            />
        );

        const timedTextEditor = (
            <TimedTextEditor
                transcriptData={this.state.transcriptData}
                timecodeOffset={this.state.timecodeOffset}
                onWordClick={this.handleWordClick}
                playMedia={this.handlePlayMedia}
                isPlaying={this.handleIsPlaying}
                currentTime={this.state.currentTime}
                mediaUrl={this.props.mediaUrl}
                isScrollIntoViewOn={this.state.isScrollIntoViewOn}
                showTimecodes={this.state.showTimecodes}
                showSpeakers={this.state.showSpeakers}
                ref={this.timedTextEditorRef}
                title={this.props.title ? this.props.title : Date.now()}
            />
        );



        const header = (
            <Header
                mediaUrl={this.props.mediaUrl}
                mediaControls={this.videoRef.current ? mediaControls : null}
            />
        );

        return (
            <div className={style.container}>
                {this.props.mediaUrl ? header : null}

                <div className={style.grid}>
                    <section className={style.row} >
                        <aside className={style.aside} style={{display: "none"}}>
                            {this.props.mediaUrl ? audioPlayer : null}
                        </aside>

                        <main
                            className={
                                style.mainWithAudio
                            }
                        >
                            {this.props.mediaUrl && this.props.transcriptData.sentences.length !== 0
                                ? timedTextEditor
                                : null}
                        </main>
                    </section>
                </div>
            </div>
        );
    }
}

TranscriptDisplay.propTypes = {
    onClick: PropTypes.func,
    title: PropTypes.string,
    mediaUrl: PropTypes.string,
    fileName: PropTypes.string,
    transcriptData: PropTypes.object,
    language: PropTypes.string,
    upload: PropTypes.func
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetTimestamp: () => dispatch({ type: "RESET_TIMESTAMP" })
    }
};

export default connect(null, mapDispatchToProps,null, {forwardRef: true})(TranscriptDisplay);