const conversationTotalDuration = (conversations) => {
    const totalDurations = conversations.reduce(function (previous, current) {
        if (!previous.finalAudio)
            return previous + current.finalAudio.duration;
        return previous.finalAudio.duration + current.finalAudio.duration;
    }, 0);

    let hours   = Math.floor(totalDurations / 3600); // get hours
    let minutes = Math.floor((totalDurations - (hours * 3600)) / 60); // get minutes
    let seconds = Math.ceil(totalDurations - (hours * 3600) - (minutes * 60)); //  get seconds
    // add 0 if value < 10; Example: 2 => 02
    if (hours   < 10) {
        hours   = "0"+hours;
    }
    if (minutes < 10) {
        minutes = "0"+minutes;
    }
    if (seconds < 10) {
        seconds = "0"+seconds;
    }

    return { hours: hours, minutes: minutes, seconds: seconds }
}

export default conversationTotalDuration;