import React from 'react';
import logo from "../images/logo.png"

const Logo = (props) => {
    return (
        <img
            alt="Logo"
            src={logo}
            {...props}
        />
    );
};

export default Logo;