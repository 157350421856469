import React, {useCallback, useEffect, useState} from 'react';
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Fab,
    FormControl,
    Grid,
    IconButton, InputLabel,
    makeStyles,
    MenuItem, Select,
    Slider,
    TextField,
    Tooltip,
    Typography,
    withStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import ConversationsList from './ConversationsList';
import Conversations from '../../services/model/Conversations';
import ApiConversations from "../../services/api/ApiConversations";
import {apiProvider} from "../../services/api/utilities/Provider";
import SearchBar from "material-ui-search-bar";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import FilterListIcon from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import {useParams} from "react-router-dom";
import ApiGroupConversations from "../../services/api/ApiGroupConversations";
import {DropzoneDialog} from "material-ui-dropzone";
import PropTypes from "prop-types";
import {useAlert} from "react-alert";
import ApiGroupInformation from "../../services/api/ApiGroupInformation";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(1)
    },
    filter: {
        '& .MuiTextField-root': {
            margin: theme.spacing(2),
            width: 'auto',
        },
    },
    filterIcon: {
        'margin-left': '80%',
        padding: 16,
        paddingTop: 0,
        '&:last-child': {
            paddingBottom: 8,
        },
    },
    filterTitle: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    menuItem: {
        fontSize: 14
    },
    fab: {
        margin: 0,
        top: 'auto',
        right: 25,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    },
    formControl: {
        marginTop: theme.spacing(3),
    },
}));

const CustomSlider = withStyles({
    root: {
        color: '#87CEFA',
        height: 3,
        padding: '13px 0'
    },
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: '#fff',
        border: '2px solid currentColor',
        marginTop: -8,
        marginLeft: -9,
        boxShadow: '#ebebeb 0 2px 2px',
        '&:focus, &:hover, &$active': {
            boxShadow: '#ccc 0 2px 3px 1px',
        },
        '& .bar': {
            // display: inline-block !important;
            height: 9,
            width: 2,
            backgroundColor: 'currentColor',
            marginLeft: 1,
            marginRight: 1,
        },
    },
    valueLabel: {
        left: 'calc(-50%)',
    },
    active: {},
    track: {
        height: 3,
    },
    rail: {
        color: '#d8d8d8',
        opacity: 1,
        height: 3,
    },
})(Slider);

function ConversationNameDialog(props) {
    const classes = useStyles();
    const { createDialog, setCreateDialog, submitEnabled, setSubmitEnabled, language, handleLanguageChange, handleCreateDialog } = props;
    const [ languageList, setLanguageList ] = useState(['english']);

    const handleTextFieldChange = (e) => {
        if (e.target.value)
            setSubmitEnabled(true);
        else
            setSubmitEnabled(false);
        handleCreateDialog(e.target.value);
    }

    useEffect(() => {
        apiProvider.auth(sessionStorage.getItem("accessToken"));
        ApiGroupInformation.getSingle("user").then(
            res => {
                setLanguageList(res.allowedLanguages)
            }
        );
    }, []);

    return (
        <Dialog open={createDialog.isOpen}>
            <DialogTitle>
                {createDialog.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {createDialog.content}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New Conversation Name"
                    fullWidth
                    onChange={handleTextFieldChange}
                />
                <FormControl className={classes.formControl}>
                    <InputLabel shrink id="language-select">
                        Language
                    </InputLabel>
                    <Select
                        labelId="language-select-label"
                        id="language-select"
                        value={language}
                        onChange={handleLanguageChange}
                        displayEmpty
                    >
                        {languageList.map((language) => (
                            <MenuItem key={language} value={language}>
                                {language}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => {
                        handleCreateDialog("");
                        setSubmitEnabled(false);
                        setCreateDialog({ ...createDialog, isOpen: false });
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    disabled={!submitEnabled}
                    onClick={ createDialog.onConfirm }
                >
                    Create
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConversationNameDialog.propTypes = {
    createDialog: PropTypes.object.isRequired,
    setCreateDialog: PropTypes.func.isRequired,
    handleCreateDialog: PropTypes.func.isRequired
};

const ConversationsListView = () => {
    const classes = useStyles();
    const reactParams = useParams();
    const [conversations, setConversations] = useState([]);
    const [filterStatus, setFilterStatus] = useState('any');
    const [filterLanguage, setFilterLanguage] = useState('any');
    const [filterParticipants, setFilterParticipants] = useState('any');
    const [filterDuration, setFilterDuration] = useState([]);
    const [filterMinMaxDuration, setFilterMinMaxDuration] = useState([]);
    const [filterMinMaxDateRange, setFilterMinMaxDateRange] = useState({});
    const [filterDateRange, setFilterDateRange] = useState({});
    const [searched, setSearched] = useState("");
    const [originalConversations, setOriginalConversations] = useState([]);
    const [open, setOpen] = useState(false);
    const [openUpload, setOpenUpload ] = useState(false);
    const [createDialog, setCreateDialog] = useState({ isOpen: false, title: '', content: '' });
    const [submitEnabled, setSubmitEnabled] = useState(false);
    const [conversationName, setConversationName] = useState("")
    const [createFile, setCreateFile] = useState("")
    const [language, setLanguage] = useState("english");
    const alert = useAlert();

    const loadConversations = useCallback(async () => {
        let conversationsListData = [];
        let conversationList = [];
        let dateList;
        let durationList;
        apiProvider.auth(sessionStorage.getItem("accessToken"))
        let query;
        if (Object.keys(reactParams).length !== 0){
            query = {
                params: {
                    limit: 100000,
                    user: reactParams.user
                }
            }

            await ApiGroupConversations.getAll(query).then(
                res => {
                    conversationList = res.docs
                }
            );
        }
        else{
            query = {
                params: {
                    limit: 100000
                }
            }

            await ApiConversations.getAll(query).then(
                res => {
                    conversationList = res.docs;
                }
            );
        }
        conversationList.forEach((conversation) => {
            conversationsListData.push(new Conversations(conversation._id, conversation.createdAt, conversation.name, conversation.speakers.length, conversation.language, conversation.status, conversation.finalAudio.duration));
        });
        setConversations(conversationsListData);
        setOriginalConversations(conversationsListData);

        durationList = conversationsListData.map(function (obj) { return obj.duration; });
        if (durationList.length !== 0){
            setFilterDuration([Math.floor(Math.min(...durationList) / 60), Math.ceil(Math.max(...durationList) / 60)]);
            setFilterMinMaxDuration([Math.floor(Math.min(...durationList) / 60), Math.ceil(Math.max(...durationList) / 60)]);
        }
        else{
            setFilterDuration([0, 0]);
            setFilterMinMaxDuration([0, 0]);
        }

        dateList = conversationsListData.map(function (obj) { return obj.createdAt; });
        setFilterMinMaxDateRange({ startDate : moment(dateList[dateList.length - 1]), endDate : moment(dateList[0]) });
        setFilterDateRange({ startDate : moment(dateList[dateList.length - 1]), endDate : moment(dateList[0]) });
    }, [reactParams]);

    useEffect(() => {
        loadConversations();
        return () => {
            setConversations([]);
            setOriginalConversations([]);
            setFilterDuration([]);
            setFilterMinMaxDuration([]);
            setFilterMinMaxDateRange([]);
            setFilterDateRange([]);
        };
    }, [loadConversations]);

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal)
    };

    const cancelSearch = () => {
        setSearched("");
    };

    const handleFilterLabel = (data) => {
        return ['any', ...new Set(data)]
    };

    const handleStatusFilter = (event) => {
        setFilterStatus(event.target.value);
    };

    const handleLanguageFilter = (event) => {
        setFilterLanguage(event.target.value);
    };


    const handleParticipantsFilter = (event) => {
        setFilterParticipants(event.target.value);
    };

    const handleDurationFilter = (event, newValue) => {
        setFilterDuration(newValue);
    };

    const handleDateRangeFilter = (start, end) => {
        setFilterDateRange({
            startDate: start,
            endDate: end,
        });
    };

    const filterRows = () => {
        const filteredRows = originalConversations.filter((conversation) => {
            return conversation.name.toLowerCase().includes(searched.toLowerCase()) &&
                moment(conversation.createdAt).isSameOrAfter(filterDateRange.startDate) && moment(conversation.createdAt).isSameOrBefore(filterDateRange.endDate) &&
                ( filterStatus.toString() !== 'any' ? conversation.status.toLowerCase().includes(filterStatus.toLowerCase()) : true) && ( filterLanguage.toString() !== 'any' ? conversation.language.toLowerCase() === (filterLanguage.toLowerCase()) : true) &&
                ( filterParticipants.toString() !== 'any' ? parseInt(conversation.participants) === (parseInt(filterParticipants)) : true) &&
                conversation.duration >= filterDuration[0]*60 && conversation.duration <= filterDuration[1]*60;
        });
        setConversations(filteredRows);
        console.log("Filtered or searched conversations list.")
    };

    const resetFilters = () => {
        setFilterDateRange({
            startDate: filterMinMaxDateRange.startDate,
            endDate: filterMinMaxDateRange.endDate
        });
        setFilterDuration(filterMinMaxDuration);
        setFilterParticipants("any");
        setFilterStatus("any");
        setFilterLanguage("any");
        setConversations(originalConversations);
        console.log("Reset all filters.")
    };

    const handleFilterClickOpen = () => {
        setOpen(true);
      };
    
      const handleFilterClose = () => {
        setOpen(false);
      };

    useEffect(() => {
        filterRows();
    }, [searched, filterStatus, filterLanguage, filterDuration, filterParticipants, filterDateRange]);// eslint-disable-line react-hooks/exhaustive-deps

    const handleCreateDialog = (name) => {
        setConversationName(name);
    }

    const handleCreateConversation = (file) => {
        setCreateFile(file);
        setCreateDialog({
            ...createDialog,
            isOpen: false
        });
    }

    useEffect(() => {
        if (conversationName !== "" && createDialog.isOpen === false){
            apiProvider.auth(sessionStorage.getItem("accessToken"));
            let conversation = new Conversations(null, null, conversationName, null, language, null, null);
            ApiConversations.post(conversation).then(
                res => {
                    if (res._id !== undefined){
                        let bodyFormData = new FormData();
                        bodyFormData.append('file', createFile, 'new_conversation.wav');
                        ApiConversations.upload(bodyFormData, res._id, 'audio').then(
                            res => {
                                if (res.message === "success"){
                                    loadConversations();
                                    console.log("New conversation is created");
                                    alert.success("New conversation is created successfully!");
                                }
                                else{
                                    alert.error("Conversation is failed to create")
                                }
                            }
                        )
                    }
                    else{
                        alert.error("Conversation is failed to create")
                    }
                }
            );
        }
    }, [conversationName, createDialog, createFile])// eslint-disable-line react-hooks/exhaustive-deps

    const handleLanguageChange = (e) => {
        setLanguage(e.target.value)
    }

    return (
        <Page
            className={classes.root}
            title="Conversations"
        >
            <Container maxWidth={false}>
                <div
                    className={classes.root}
                >
                    <Box mt={3}>
                        <Card>
                            <CardContent>
                                <Box maxWidth={500}>
                                    <Grid container spacing={5}>
                                        <Grid item>
                                            <SearchBar
                                                value={searched}
                                                onChange={(searchVal) => requestSearch(searchVal)}
                                                onCancelSearch={() => cancelSearch()}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Tooltip title={'Filter'}>
                                                <IconButton
                                                    color="inherit"
                                                    size={"medium"}
                                                    onClick={handleFilterClickOpen}
                                                >
                                                    <FilterListIcon />
                                                </IconButton>
                                            </Tooltip>

                                            <Dialog fullScreen open={open} onClose={handleFilterClose}>
                                                <DialogContent>
                                                    <IconButton edge="start" onClick={handleFilterClose} aria-label="close">
                                                        <CloseIcon />
                                                    </IconButton>
                                                    <Divider variant={"middle"}/>
                                                    <Grid container direction={"column"} className={classes.filter} spacing={2} justify={'space-between'}>
                                                        <Grid item>
                                                            { Object.keys((filterDateRange)).length !== 0 ? <DateRangePicker
                                                                onCallback={handleDateRangeFilter}
                                                                initialSettings={{
                                                                    showDropdowns: true,
                                                                    startDate: filterDateRange.startDate.format('MM/DD/YYYY'),
                                                                    endDate: filterDateRange.endDate.format('MM/DD/YYYY'),
                                                                    minDate: filterMinMaxDateRange.startDate.format('MM/DD/YYYY'),
                                                                    maxDate: filterMinMaxDateRange.endDate.format('MM/DD/YYYY')
                                                                }}
                                                            >
                                                                <TextField
                                                                    id="outlined-date-range"
                                                                    label="Date range"
                                                                    value={ filterDateRange.startDate.format('DD MMM YYYY') + ' - ' + filterDateRange.endDate.format('DD MMM YYYY') }
                                                                    variant="outlined"
                                                                    style = {{width: '50%'}}
                                                                />
                                                            </DateRangePicker> : null }
                                                        </Grid>

                                                        <Grid item sm={6}>
                                                            <Typography id="range-slider" gutterBottom style={{marginLeft: 20}}>
                                                                Duration range
                                                                <span>
                                                            <CustomSlider
                                                                value={filterDuration}
                                                                min={filterMinMaxDuration[0]}
                                                                max={filterMinMaxDuration[1]}
                                                                onChange={handleDurationFilter}
                                                                aria-labelledby="range-slider"
                                                                valueLabelDisplay={"auto"}
                                                            />
                                                        </span>
                                                                {filterDuration[0]} min - {filterDuration[1]} min
                                                            </Typography>
                                                        </Grid>

                                                        <Grid item>
                                                            <TextField
                                                                id="outlined-select-status"
                                                                select
                                                                label="Status"
                                                                value={ filterStatus }
                                                                onChange={ handleStatusFilter }
                                                                variant="outlined"
                                                            >
                                                                {handleFilterLabel(originalConversations.map(function (obj) {
                                                                    return obj.status;
                                                                })).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item>
                                                            <TextField
                                                                id="outlined-select-language"
                                                                select
                                                                label="Language"
                                                                value={ filterLanguage }
                                                                onChange={ handleLanguageFilter }
                                                                variant="outlined"
                                                            >
                                                                {handleFilterLabel(originalConversations.map(function (obj) {
                                                                    return obj.language;
                                                                })).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item>
                                                            <TextField
                                                                id="outlined-select-participants"
                                                                select
                                                                label="Participants"
                                                                value={ filterParticipants }
                                                                onChange={ handleParticipantsFilter }
                                                                variant="outlined"
                                                            >
                                                                {handleFilterLabel(originalConversations.map(function (obj) {
                                                                    return obj.participants;
                                                                })).map((option) => (
                                                                    <MenuItem key={option} value={option}>
                                                                        {option}
                                                                    </MenuItem>
                                                                ))}
                                                            </TextField>
                                                        </Grid>

                                                        <Grid item direction="row" container={true} justify={"space-between"}>
                                                            <Grid item xs={"auto"}>
                                                                <Button variant="contained" color="primary" onClick={resetFilters}>
                                                                    RESET FILTERS
                                                                </Button>
                                                            </Grid>

                                                            <Grid item>
                                                                <Button variant="contained" color="primary" onClick={handleFilterClose}>
                                                                    APPLY
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </DialogContent>
                                            </Dialog>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Box>
                </div>
                <Box mt={3}>
                    <ConversationsList conversations={conversations} userId={reactParams}/>
                </Box>
                {
                    Object.keys(reactParams).length === 0 ?
                        <Fab color="primary" aria-label="add" className={classes.fab} onClick={() => setOpenUpload(true)}>
                            <AddIcon />
                        </Fab> : null
                }
            </Container>
            <DropzoneDialog
                acceptedFiles={['audio/*','video/*']}
                cancelButtonText={"Cancel"}
                submitButtonText={"Upload"}
                filesLimit={1}
                maxFileSize={1000000000}
                open={openUpload}
                onClose={() => setOpenUpload(false)}
                onSave={(files) => {
                    setCreateDialog({
                        isOpen: true,
                        title: 'NEW CONVERSATION NAME',
                        content: "Give this conversation a name in the text field below.",
                        onConfirm: () => {
                            setSubmitEnabled(false)
                            handleCreateConversation(files[0]);
                        }
                    })
                    setOpenUpload(false)
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
            <ConversationNameDialog
                createDialog={createDialog}
                setCreateDialog={setCreateDialog}
                language={language}
                handleLanguageChange={handleLanguageChange}
                submitEnabled={submitEnabled}
                setSubmitEnabled={setSubmitEnabled}
                handleCreateDialog={handleCreateDialog}
            />
        </Page>
    );
};

export default ConversationsListView;