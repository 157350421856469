const fileDownload = (content, filename, contentType) => {
    console.log("download");
    const type = contentType || "application/octet-stream";
    const link = document.createElement("a");
    const blob = new Blob([content], { type: type });

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    // Firefox fix - cannot do link.click() if it's not attached to DOM in firefox
    // https://stackoverflow.com/questions/32225904/programmatical-click-on-a-tag-not-working-in-firefox
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const jsonEdit = (res) => {
    let transcripts = [];
    const obj = { "transcripts" : res.sentences };

    obj.transcripts.forEach((transcript) => {
        let {user, words} = transcript;
        let sentences=[];
        words.forEach((word) => {
            delete word._id;
            sentences.push(word.text);
        })
        user =  { "name" : user.name, "email" : user.email };
        let sentenceObj = { user, "sentences": sentences.join(" "), words };
        transcripts.push(sentenceObj);
    })

    const resultObj = { "num_speakers": res.speakers.length, "transcripts" : transcripts };
    return JSON.stringify(resultObj, null, 2);
};

export { fileDownload, jsonEdit };