import generateEntitiesRanges from "./generateEntititiesRanges";

const ConversationToDraft = conversationJson => {
    const result = [];
    let paragraph = { text: '', speaker: ''};
    let draftWord = {
        start: 0,
        confidence: 0,
        end: 0,
        word: "",
        index: 0
    };

    if (conversationJson.sentences !== []) {
        let i = 0;
        conversationJson.sentences.forEach((sentence) => {
            let draftWords=[];
            let tmpWords = [];

            paragraph["speaker"] = sentence.user.name
            sentence.words.forEach((word) => {
                draftWord={};
                draftWord["start"] = word.startTime;
                draftWord["end"] = word.endTime;
                draftWord["confidence"] = word.score;
                draftWord["word"] = word.text;
                draftWord["index"] = i;
                i += 1;
                tmpWords.push(word.text);
                draftWords.push(draftWord);
            })


            const draftJsContentBlockParagraph = {
                text: tmpWords.join(" "),
                type: 'paragraph',
                data: {
                    speaker: sentence.user.name,
                    words: draftWords,
                    start: sentence.startTime
                },
                // the entities as ranges are each word in the space-joined text,
                // so it needs to be compute for each the offset from the beginning of the paragraph and the length
                entityRanges: generateEntitiesRanges(draftWords, 'word') // wordAttributeName
            };
            result.push(draftJsContentBlockParagraph)
        })
    }

    return result;
};

export default ConversationToDraft;
