import {ApiCore} from "./utilities/Core";

const url = 'conversations/group';

const ApiGroupConversations = new ApiCore({
    getSingle: false,
    getAll: true,
    patch: false,
    post: false,
    remove: false,
    url: url,
});

export default ApiGroupConversations;