import React  from 'react';
import {
    Box, Card, CardActionArea, CardContent,
    Container, Grid, Link,
    makeStyles, Typography
} from '@material-ui/core';
import Page from '../../components/Page';
import {faShieldAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import NavigateNextOutlinedIcon from "@material-ui/icons/NavigateNextOutlined";
import {Link as RouterLink} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    }
}));

const SettingsListView = () => {
    const classes = useStyles();

    return (
        <Page
            className={classes.root}
            title="Settings"
        >
            <Container maxWidth={false}>
                <Box maxWidth={500}>
                    <Typography variant={"h1"} gutterBottom className={classes.root}>
                        Settings
                    </Typography>
                </Box>

                <Grid container>
                    <Grid item xs={12} sm={3}>
                        <Link
                            component={RouterLink}
                            to= {'/app/settings/account'}
                            variant="h6"
                            underline={'none'}
                        >
                            <Card raised={true}>
                                <CardActionArea>
                                    <CardContent>
                                        <Box display="flex" component={"span"} p={2}>
                                            <FontAwesomeIcon icon={ faShieldAlt } size={'6x'} color={'#1E90FF'}/>
                                        </Box>
                                        <Typography gutterBottom variant="h2" component="h2" className={classes.wrapIcon}>
                                            Account preferences<NavigateNextOutlinedIcon fontSize={"large"} />
                                        </Typography>
                                        <Typography variant="body1" color="textSecondary" component="p">
                                            Update your password
                                        </Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Link>

                    </Grid>
                </Grid>

            </Container>
        </Page>
    );
};

export default SettingsListView;