import React from 'react';
import {Navigate} from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import LoginView from "./views/auth/LoginView";
import RegisterView from "./views/auth/RegisterView";
import NotFoundView from './views/errors/NotFoundView';
import MenuLayout from "./layouts/MenuLayout";
import ConversationsListView from "./views/conversations/ConversationsListView";
import ConversationView from "./views/conversations/ConversationView";
import SettingsListView from "./views/settings/SettingsListView";
import AccountSettingView from "./views/settings/AccountSettingView";
import ForgotPasswordView from "./views/auth/ForgotPasswordView";
import ResetPasswordView from "./views/auth/ResetPasswordView";
import GroupConversationsList from "./views/groupConversations/GroupConversationsList";
import Dashboard from "./views/dashboard/Dashboard";

const routes = (isLoggedIn) => [
    {
        path: 'app',
        element: isLoggedIn ? <MenuLayout /> : <Navigate to="/" />,
        children: [
            { path: 'dashboard/user', element: <Dashboard type={'user'} /> },
            { path: 'dashboard/group', element: <Dashboard type={'group'} /> },
            { path: 'conversations', element: <ConversationsListView /> },
            { path: 'conversations/:id', element: <ConversationView /> },
            { path: 'settings', element: <SettingsListView /> },
            { path: 'settings/account', element: <AccountSettingView /> },
            { path: 'group', element: <GroupConversationsList />},
            { path: 'group/:user', element: <ConversationsListView />},
            { path: '*', element: <Navigate to="/404" /> }
        ],
    },
    {
        path: '/',
        element: <MainLayout />,
        children: [
            { path: 'login', element: <LoginView /> },
            { path: 'register', element: <RegisterView />},
            { path: 'forgot_password', element: <ForgotPasswordView />},
            { path: 'reset_password', element: <ResetPasswordView />},
            { path: '404', element: <NotFoundView /> },
            { path: '/', element: <Navigate to="/login" /> },
            { path: '*', element: <Navigate to="/404" /> }
        ]
    }
];

export default routes;