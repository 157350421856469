import React from 'react';
import {
    EditorBlock,
} from 'draft-js';

import SpeakerLabel from './SpeakerLabel';

import {
    shortTimeCode
} from '../../util/TimeCodeConverter';

import style from './WrapperBlock.module.css';
import VisibilitySensor from "react-visibility-sensor";
import {connect} from "react-redux";

class WrapperBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            speaker: '',
            start: 0,
            timecodeOffset: this.props.blockProps.timecodeOffset
        };

        this.onChangeVisibility = this.onChangeVisibility.bind(this);
    }

    componentDidMount() {
        const { block } = this.props;
        const speaker = block.getData().get('speaker');

        const start = block.getData().get('start');

        this.setState({
            speaker: speaker,
            start: start
        });
    }

    // reducing unnecessary re-renders
    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps.block.getText() !== this.props.block.getText()) {
            return true;
        }

        if (nextProps.blockProps.showSpeakers !== this.props.blockProps.showSpeakers) {
            return true;
        }

        if (nextProps.blockProps.showTimecodes !== this.props.blockProps.showTimecodes) {
            return true;
        }

        if (nextProps.blockProps.timecodeOffset !== this.props.blockProps.timecodeOffset) {
            return true;
        }

        if (nextState.speaker !== this.state.speaker) {
            return true;
        }

        if(nextProps.block.getData().get('speaker') !== this.state.speaker){
            return true;
        }
        return false;
    };

    componentDidUpdate  = (prevProps, prevState) =>{

        if(prevProps.block.getData().get('speaker') !== prevState.speaker){
            this.setState({
                speaker: prevProps.block.getData().get('speaker')
            })

            return true;
        }
    }

    handleTimeCodeClick = () => {
        this.props.blockProps.onWordClick(this.state.start);
    };

    onChangeVisibility = (isVisible) => {
        this.setState({visible: isVisible});
    }

    render() {
        this.props.setTimestamp(this.state.start);
        let startTimeCode = this.state.start;
        if (this.props.blockProps.timecodeOffset) {
            startTimeCode += this.props.blockProps.timecodeOffset;
        }

        const speakerElement = (
            <SpeakerLabel
                name={ this.state.speaker }
            />
        );

        const timeCodeElement = (
            <span className={ style.time } onClick={ this.handleTimeCodeClick } data-start={startTimeCode} id={ `timeCodeElement-${startTimeCode}` }>
                {shortTimeCode(startTimeCode)}
            </span>
        );

        let containmentDOMRect = document.querySelector(".notranslate");
        return (
            containmentDOMRect ? (
                <div className={ style.WrapperBlock }>
                    <div
                        className={ [ style.markers, style.unselectable ].join(' ') }
                        contentEditable={ false }
                    >
                        {this.props.blockProps.showSpeakers ? speakerElement : ''}

                        {this.props.blockProps.showTimecodes ? timeCodeElement : ''}
                    </div>
                    <VisibilitySensor partialVisibility={true} offset={{ top: -500, bottom: -500 }} onChange={this.onChangeVisibility} active={!this.state.visible} containment={containmentDOMRect} intervalCheck={ false } scrollCheck>
                        {({isVisible}) =>  isVisible ?
                            <div className={ style.text }>
                                <EditorBlock { ...this.props } />
                            </div> : <span className={ style.loadingBlockPlaceholder }>...</span>
                        }
                    </VisibilitySensor>
                </div>
            ):null
        )
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setTimestamp: (data) => dispatch({ type: "SET_TIMESTAMP", payload: data })
    }
};

export default connect(null, mapDispatchToProps)(WrapperBlock);