import {ApiCore} from "./utilities/Core";

const url = 'auth/register';

// plural and single may be used for message logic if needed in the ApiCore class.

const ApiRegister = new ApiCore({
    post: true,
    url: url,
});

export default ApiRegister;