import secondsToTimeCode from './SecondsToTimeCode';
import timeCodeToSecondsHelper from './TimeCodeToSeconds';
import padTimeToTimeCode from './PadTimeToTimeCode';

const timeCodeToSeconds = (time) => {
    if (typeof time === 'string') {
        const resultPadded = padTimeToTimeCode(time);
        return timeCodeToSecondsHelper(resultPadded);
    }

    // assuming it receive timeCode as seconds as string '600'
    return parseFloat(time);
};

const shortTimeCode = (time) => {
    const timeCode = secondsToTimeCode(time);

    return timeCode.slice(0, -3);
};

export { secondsToTimeCode, timeCodeToSeconds, shortTimeCode };