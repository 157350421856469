import React from "react";
import { Editor } from "draft-js";


import WrapperBlock from './WrapperBlock';

// NOTE: custom editor is in a separate class to minimise re-renders
// if considering refactoring, removing the separate class, please double check
// that doing so does not introduce unnecessary re-renders first.
class CustomEditor extends React.Component {
    handleWordClick = e => {
        this.props.onWordClick(e);
    };

    renderBlockWithTimecodes = () => {
        return {
            component: WrapperBlock,
            props: {
                showSpeakers: this.props.showSpeakers,
                showTimecodes: this.props.showTimecodes,
                timecodeOffset: this.props.timecodeOffset,
                editorState: this.props.editorState,
                onWordClick: this.handleWordClick,
            }
        };
    };

    shouldComponentUpdate(nextProps) {
        if (nextProps.editorState !== this.props.editorState) {
            return true;
        }

        return false;
    }

    handleOnChange = e => {
        this.props.onChange(e);
    };


    render() {
        return (
            <Editor
                editorState={this.props.editorState}
                stripPastedStyles
                blockRendererFn={this.renderBlockWithTimecodes}
                onChange={this.handleOnChange}
            />
        );
    }
}

export default CustomEditor;