// Use the initialState as a default value
export default function timeStampReducer(state = [], action) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        case "SET_TIMESTAMP":
            return [
                ...state, action.payload
            ];

        case "RESET_TIMESTAMP":
            return [];

        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}