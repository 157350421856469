const timeCodeToFrames = function (tc, fps) {

    const s = tc.split(':');
    let frames = parseInt(s[3]);
    frames += parseInt(s[2]) * fps;
    frames += parseInt(s[1]) * (fps * 60);
    frames += parseInt(s[0]) * (fps * 60 * 60);

    return frames;
};

const TimeCodeToSecondsHelper = function (tc, framePerSeconds) {
    let fps = 25;
    if (framePerSeconds !== undefined) {
        fps = framePerSeconds;
    }
    const frames = timeCodeToFrames(tc, fps);

    return Number(Number(frames / fps).toFixed(2));
};

export default TimeCodeToSecondsHelper;