import React from "react";
import PropTypes from "prop-types";

import {
    EditorState,
    CompositeDecorator,
    convertFromRaw,
    convertToRaw,
} from "draft-js";

import Word from './Word';

import style from './index.module.css';
import CustomEditor from "./CustomEditor";
import SttAdapter from "../../util/Adapters/SttJsonAdapter";
import exportAdapter from "../ExportAdapters";

class TimedTextEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editorState: EditorState.createEmpty()
        };
    }

    componentDidMount() {
        this.loadData();
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        if (nextProps !== this.props) return true;

        if (nextState !== this.state) return true;

        return false;
    };

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.transcriptData !== this.props.transcriptData)
            this.loadData();
        if (
            prevProps.timecodeOffset !== this.props.timecodeOffset ||
            prevProps.showSpeakers !== this.props.showSpeakers ||
            prevProps.showTimecodes !== this.props.showTimecodes
        ) {
            // forcing a re-render is an expensive operation and
            // there might be a way of optimising this at a later refactor (?)
            // the issue is that WrapperBlock is not update on TimedTextEditor
            // state change otherwise.
            // for now compromising on this, as setting timecode offset, and
            // display preferences for speakers and timecodes are not expected to
            // be very frequent operations but rather one time setup in most cases.
            this.forceRenderDecorator();
        }
    }

    onChange = editorState => {
        // https://draftjs.org/docs/api-reference-editor-state#lastchangetype
        // https://draftjs.org/docs/api-reference-editor-change-type
        // doing editorStateChangeType === 'insert-characters'  is triggered even
        // outside of draftJS eg when clicking play button so using this instead
        // see issue https://github.com/facebook/draft-js/issues/1060
        // also "insert-characters" does not get triggered if you delete text
        if (this.state.editorState.getCurrentContent() !== editorState.getCurrentContent()) {
            if (this.saveTimer !== undefined) {
                clearTimeout(this.saveTimer);
            }
            this.saveTimer = setTimeout(() => {
                this.setState(
                    () => ({
                        editorState
                    })
                );
            }, 1000);
        }
    };

    getEditorContent(exportFormat, title) {
        const format = exportFormat || 'json';
        const tmpEditorState = this.state.editorState;

        return exportAdapter(
            convertToRaw(tmpEditorState.getCurrentContent()),
            format
        );
    }


    loadData() {
        if (this.props.transcriptData !== null) {
            const blocks = SttAdapter(this.props.transcriptData);
            this.setState({ originalState: blocks });
            this.setEditorContentState(blocks);
        }
    }

    // click on words - for navigation
    // eslint-disable-next-line class-methods-use-this
    handleDoubleClick = event => {
        // nativeEvent --> React giving you the DOM event
        let element = event.nativeEvent.target;
        // find the parent in Word that contains span with time-code start attribute
        while (!element.hasAttribute("data-start") && element.parentElement) {
            element = element.parentElement;
        }

        if (element.hasAttribute("data-start")) {
            const t = parseFloat(element.getAttribute("data-start"));
            this.props.onWordClick(t);
        }
        console.log("Double click on word");
    };

    setEditorContentState = data => {
        const contentState = convertFromRaw(data);
        // eslint-disable-next-line no-use-before-define
        const editorState = EditorState.createWithContent(contentState, decorator);

        this.setState({ editorState }, ()=>{
            this.forceRenderDecorator();
        });
    };

    // Helper function to re-render this component
    // used to re-render WrapperBlock on timecode offset change
    // or when show / hide preferences for speaker labels and timecodes change
    forceRenderDecorator = () => {
        const contentState = this.state.editorState.getCurrentContent();
        const decorator = this.state.editorState.getDecorator();
        const newState = EditorState.createWithContent(contentState, decorator);
        const newEditorState = EditorState.push(newState, contentState);
        this.setState({ editorState: newEditorState });
    };

    getCurrentWord = () => {
        const currentWord = {
            start: "NA",
            end: "NA"
        };

        if (this.props.transcriptData) {
            const contentState = this.state.editorState.getCurrentContent();
            // TODO: using convertToRaw here might be slowing down performance(?)
            const contentStateConvertEdToRaw = convertToRaw(contentState);
            const entityMap = contentStateConvertEdToRaw.entityMap;

            for (var entityKey in entityMap) {
                const entity = entityMap[entityKey];
                const word = entity.data;

                if (
                    word.start <= this.props.currentTime &&
                    word.end >= this.props.currentTime
                ) {
                    currentWord.start = word.start;
                    currentWord.end = word.end;
                }
            }
        }

        if (currentWord.start !== "NA") {
            if (this.props.isScrollIntoViewOn) {
                const currentWordElement = document.querySelector(
                    `span.Word[data-start="${ currentWord.start }"]`
                );
                try {
                    currentWordElement.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center'
                    });
                }
                catch (e){
                    console.log("Timestamp is scrolled into view");
                }
            }
        }

        return currentWord;
    };

    onWordClick = e => {
        this.props.onWordClick(e);
    };

    render() {
        const currentWord = this.getCurrentWord();
        const highlightColour = "#69e3c2";
        const unplayedColor = "#767676";
        const correctionBorder = "1px dotted blue";

        // Time to the nearest half second
        const time = Math.round(this.props.currentTime * 4.0) / 4.0;

        const editor = (
            <section
                className={style.editor}
                onDoubleClick={this.handleDoubleClick}
                // TODO: decide if on mobile want to have a way to "click" on words
                // to play corresponding media
                // a double tap would be the ideal solution
                // onTouchStart={ event => this.handleDoubleClick(event) }
            >
                <style scoped>
                    {`span.Word[data-start="${ currentWord.start }"] { background-color: ${ highlightColour }; text-shadow: 0 0 0.1px black }`}
                    {`span.Word[data-start="${ currentWord.start }"]+span { background-color: ${ highlightColour } }`}
                    {`span.Word[data-prev-times~="${ Math.floor(
                        time
                    ) }"] { color: ${ unplayedColor } }`}
                    {`span.Word[data-prev-times~="${ time }"] { color: ${ unplayedColor } }`}
                    {`span.Word[data-confidence="low"] { border-bottom: ${ correctionBorder } }`}
                </style>
                <CustomEditor
                    editorState={this.state.editorState}
                    onChange={this.onChange}
                    stripPastedStyles
                    showSpeakers={this.props.showSpeakers}
                    showTimecodes={this.props.showTimecodes}
                    timecodeOffset={this.props.timecodeOffset}
                    onWordClick={this.onWordClick}
                />
            </section>
        );

        return (
            <section>{this.props.transcriptData !== null ? editor : null}</section>
        );
    }
}

// DraftJs decorator to recognize which entity is which
// and know what to apply to what component
const getEntityStrategy = mutability => (
    contentBlock,
    callback,
    contentState
) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
            return false;
        }

        return contentState.getEntity(entityKey).getMutability() === mutability;
    }, callback);
};

// decorator definition - Draftjs
// defines what to use to render the entity
const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy('MUTABLE'),
        component: Word
    }
]);

TimedTextEditor.propTypes = {
    transcriptData: PropTypes.object,
    mediaUrl: PropTypes.string,
    onWordClick: PropTypes.func,
    isPlaying: PropTypes.func,
    playMedia: PropTypes.func,
    currentTime: PropTypes.number,
    isScrollIntoViewOn: PropTypes.bool,
    timecodeOffset: PropTypes.number,
    showSpeakers: PropTypes.bool,
    showTimecodes: PropTypes.bool,
};

export default TimedTextEditor;