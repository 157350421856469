import {Box, Button, ButtonGroup, Card, CardContent, CardHeader, Divider, Typography} from "@material-ui/core";
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import PropTypes from "prop-types";
import React, {PureComponent} from "react";

const COLORS = ["#0088fe", "#8493ff", "#baa0ff", "#e1b1fe", "#ffc5ff", "#ffabdf", "#ff94b1", "#ff847c", "#ff8042"];

class CustomizedAxisTick extends PureComponent {
    render() {
        // eslint-disable-next-line no-unused-vars
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={10} fontSize={12} textAnchor="end" fill="#666" transform="rotate(-60)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

const style = {
    boxShadow: 10,
    borderRadius: 20
}

const TimeSeries = (props) => (
    <Card
        sx={{
            height: '100%',
            boxShadow: 3,
            borderRadius: 16
        }}
        raised
        style={style}
    >
        <CardHeader
            title={"Total Conversations By Date"}
            action={ props.timeSeriesDisplay ?
                <ButtonGroup variant="contained" style={{border: "solid 1px #000000"}}>
                    <Button onClick={() => props.setTimeSpan('day')} size={"small"} color={
                        props.timeSpan === 'day' ? "secondary" : null
                     } style={ props.timeSpan === 'day' ? null : { backgroundColor: "#FFFFFF" }}>
                        Last 24 hours
                    </Button>
                    <Button onClick={() => props.setTimeSpan('week')} size={"small"} color={
                        props.timeSpan === 'week' ? "secondary" : null
                    } style={ props.timeSpan === 'week' ? null : { backgroundColor: "#FFFFFF" }}>
                        Last 7 days
                    </Button>
                    <Button onClick={() => props.setTimeSpan('month')} size={"small"} color={
                        props.timeSpan === 'month' ? "secondary" : null
                    } style={ props.timeSpan === 'month' ? null : { backgroundColor: "#FFFFFF" }}>
                        Last 30 days
                    </Button>
                </ButtonGroup> :
                <ButtonGroup variant="contained" color="secondary" orientation={"vertical"}>
                    <Button onClick={() => props.setTimeSpan('day')} size={"small"} color={
                        props.timeSpan === 'day' ? "primary" : "secondary"
                    }>
                        Last 24 hours
                    </Button>
                    <Button onClick={() => props.setTimeSpan('week')} size={"small"} color={
                        props.timeSpan === 'week' ? "primary" : "secondary"
                    }>
                        Last 7 days
                    </Button>
                    <Button onClick={() => props.setTimeSpan('month')} size={"small"} color={
                        props.timeSpan === 'month' ? "primary" : "secondary"
                    }>
                        Last 30 days
                    </Button>
                </ButtonGroup>
            }
        />
        <Divider />
        <CardContent>
            {
                props.data.length === 0 ?
                    <Typography  variant={"h1"} align={"center"}>
                        No Data
                    </Typography>
                    :
                    <Box
                        sx={{
                            height: 500,
                            position: 'relative'
                        }}
                    >
                        <div style={{
                            paddingBottom: '26.25%',
                            position: 'relative',
                            height: 500
                        }} >
                            <div style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '100%',
                                height: '100%'
                            }}>
                                {
                                    props.data.length !== 0 ?
                                        <ResponsiveContainer >
                                            <LineChart
                                                width={600}
                                                height={200}
                                                data={props.data}
                                                margin={{
                                                    top: 5,
                                                    right: 10,
                                                    left: 15,
                                                    bottom: 5
                                                }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                {
                                                    props.timeSeriesDisplay ?
                                                        <XAxis dataKey="date" interval={0} height={120} tick={<CustomizedAxisTick />}/>:
                                                        <XAxis dataKey="date" interval={"preserveStartEnd"} height={120} tick={<CustomizedAxisTick />}/>
                                                }

                                                <YAxis allowDecimals={false}/>
                                                <Tooltip />
                                                <Legend />
                                                { props.statuses.map((status, index) => (
                                                    <Line
                                                        type="monotone"
                                                        key={`line-${index}`}
                                                        dataKey={status}
                                                        activeDot={{ r: 8 }}
                                                        stroke={COLORS[index]}
                                                    />
                                                ))
                                                }
                                            </LineChart>
                                        </ResponsiveContainer> : null
                                }
                            </div>
                        </div>
                    </Box>
            }

        </CardContent>
    </Card>
);

TimeSeries.propTypes = {
    data: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    timeSpan: PropTypes.string.isRequired,
    setTimeSpan: PropTypes.func.isRequired,
    timeSeriesDisplay: PropTypes.bool.isRequired
};

export default TimeSeries;