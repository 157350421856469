import axios from 'axios';
import { handleResponse, handleError } from './Response';

// Define your api url from any source.
// Pulling from your .env file when on the server or from localhost when locally
const BASE_URL = 'https://api.meadow9.com';
const GATEWAY_URL = 'https://gateway.speechlab.sg';


const auth = token => {
    axios.defaults.headers.common['Authorization'] = "Bearer " + token
};

/** @param {string} resource
 * @param {object} query
 */
const getAll = (resource, query) => {
    return axios
        .get(`${BASE_URL}/${resource}`, query)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param {string} id */
const getSingle = (resource, id) => {
    return axios
        .get(`${BASE_URL}/${resource}/${id}`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param {object} model
 @param {boolean} gateway*/
const post = (resource, model, gateway) => {
    if (gateway){
        return axios
            .post(`${GATEWAY_URL}/${resource}`, model)
            .then(handleResponse)
            .catch(handleError);
    }

    return axios
        .post(`${BASE_URL}/${resource}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param {object} model */
const put = (resource, model) => {
    return axios
        .put(`${BASE_URL}/${resource}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param id
 @param {object} model */
const patch = (resource, id, model) => {
    return axios
        .patch(`${BASE_URL}/${resource}/${id}`, model)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param {string} id */
const remove = (resource, id) => {
    return axios
        .delete(`${BASE_URL}/${resource}/${id}`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param {object} model
 @param {string} type
 @param {string} id*/
const upload = (resource, model, id, type) => {
    if (type === 'transcript'){
        return axios
            .post(`${BASE_URL}/${resource}/${id}/uploadTranscript`, model )
            .then(handleResponse)
            .catch(handleError);
    }
    else if (type === 'audio'){
        return axios
            .post(`${BASE_URL}/${resource}/${id}/upload`, model, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
            .then(handleResponse)
            .catch(handleError);
    }
};

/** @param {string} resource
 @param {string} id*/
const download = (resource, id) => {
    return axios
        .get(`${BASE_URL}/${resource}/${id}/export`, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        })
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 @param {string} id*/
const complete = (resource, id) => {
    return axios
        .patch(`${BASE_URL}/${resource}/${id}/completeTranscript`)
        .then(handleResponse)
        .catch(handleError);
};

/** @param {string} resource
 * @param type
 @param {object} query*/
const report = (resource, query, type) => {
    if ( type === "time"){
        return axios
            .post(`${BASE_URL}/${resource}/report`, null, query )
            .then(handleResponse)
            .catch(handleError);
    }

    else if (type === "csv"){
        return axios
            .post(`${BASE_URL}/${resource}/report/csv`, null, query )
            .then(handleResponse)
            .catch(handleError);
    }

};

export const apiProvider = {
    auth,
    getAll,
    getSingle,
    post,
    put,
    patch,
    remove,
    upload,
    download,
    complete,
    report
};
