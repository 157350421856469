import {ApiCore} from "./utilities/Core";

const url = 'conversations';

const ApiConversations = new ApiCore({
    getSingle: true,
    getAll: true,
    patch: true,
    post: true,
    remove: true,
    upload: true,
    download: true,
    complete: true,
    report: true,
    url: url,
});

export default ApiConversations;