import {
    Card,
    CardContent,
    Grid,
    Typography
} from '@material-ui/core';
import PropTypes from "prop-types";
import AnimatedIcon from "./AnimatedIcon";

const style = {
    boxShadow: 10,
    borderRadius: 20
}

const RecordingsTotalDuration = (props) => (
    <Card
        sx={{
            height: '100%',
        }}
        style={style}
        raised
    >
        <CardContent>
            <Grid
                container
                spacing={2}
                justify="space-between"
            >
                <Grid item>
                    <Typography
                        color="textSecondary"
                        gutterBottom
                        variant="h6"
                    >
                        TOTAL DURATIONS
                    </Typography>
                    <Typography
                        color="textPrimary"
                        variant="h3"
                    >
                        {props.hours}hr{'\u00A0'}{props.minutes}min{'\u00A0'}{props.seconds}sec
                    </Typography>
                </Grid>
                <Grid item>
                    <AnimatedIcon color={"#e62e00"} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

RecordingsTotalDuration.propTypes = {
    hours: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    minutes:PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    seconds: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};

RecordingsTotalDuration.defaultProps = {
    hours: 0,
    minutes: 0,
    seconds: 0
}

export default RecordingsTotalDuration;