import {apiProvider} from './Provider';


export class ApiCore {
    constructor(options) {
        if (options.getAll) {
            this.getAll = (query) => {
                return apiProvider.getAll(options.url, query);
            };
        }

        if (options.getSingle) {
            this.getSingle = (id) => {
                return apiProvider.getSingle(options.url, id);
            };
        }

        if (options.post) {
            this.post = (model) => {
                return apiProvider.post(options.url, model, options.gateway);
            };
        }

        if (options.put) {
            this.put = (model) => {
                return apiProvider.put(options.url, model);
            };
        }

        if (options.patch) {
            this.patch = (id, model) => {
                return apiProvider.patch(options.url, id, model);
            };
        }

        if (options.remove) {
            this.remove = (id) => {
                return apiProvider.remove(options.url, id);
            };
        }

        if (options.upload) {
            this.upload = (model, id, type) => {
                return apiProvider.upload(options.url, model, id, type);
            }
        }

        if (options.download) {
            this.download = (id) => {
                return apiProvider.download(options.url, id);
            }
        }

        if (options.complete) {
            this.complete = (id, model) => {
                return apiProvider.complete(options.url, id);
            };
        }

        if (options.report) {
            this.report = (query, type) => {
                return apiProvider.report(options.url, query, type);
            };
        }

    }
}