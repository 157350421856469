import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import User from "../../services/model/User";
import {positions, useAlert} from "react-alert";
import ApiResetPassword from "../../services/api/ApiResetPassword";
import ApiForgotPassword from "../../services/api/ApiForgotPassword";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ResetPasswordView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const alert = useAlert();
    const [timer, setTimer] = useState(0);

    useEffect(()=>{
        if (timer > 0){
            setTimeout(() => setTimer(timer - 1000), 1000);
        }
    });

    const sendVerificationCode = () => {
        let user = new User(sessionStorage.getItem("resetEmail"));
        ApiForgotPassword.post(user).then(
            res => {
                if(res.message){
                    setTimer(60000);
                    alert.success(res.message);
                }
                else{
                    alert.error(res.data.message);
                }
            })
    };

    return (
        <Page
            className={classes.root}
            title="Register"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            verificationCode: '',
                            password: '',
                            passwordConfirmation: '',
                        }}
                        validationSchema={
                            Yup.object().shape({
                                verificationCode: Yup.string().max(255).required('Verification code is required'),
                                password: Yup.string().max(255).required('Password is required'),
                                passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
                            })
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            let user = new User(sessionStorage.getItem("resetEmail"), null, null, null, values.password, values.passwordConfirmation, values.verificationCode);
                            ApiResetPassword.post(user).then(
                                res => {
                                    if(res.message){
                                        alert.success(res.message);
                                        navigate('/login', { replace: true });
                                        sessionStorage.clear();
                                        console.log("Password reset is success");
                                    }
                                    else{
                                        if(Array.isArray(res.data.message)){
                                            res.data.message.forEach((message) => {
                                                alert.error(message, {timeout:30000, position: positions.BOTTOM_RIGHT});
                                            })
                                            console.log("Password reset is fail");
                                        }

                                        else{
                                            console.log("Password reset is fail");
                                            alert.error(res.data.message, {timeout:10000});
                                        }

                                    }
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Reset your password
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        The required verification code is sent to your email. Please fill in the required information.
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.verificationCode && errors.verificationCode)}
                                    fullWidth
                                    helperText={touched.verificationCode && errors.verificationCode}
                                    label="VerificationCode"
                                    margin="normal"
                                    name="verificationCode"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.verificationCode}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                                    fullWidth
                                    helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                    label="Confirm New Password"
                                    margin="normal"
                                    name="passwordConfirmation"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.passwordConfirmation}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Reset Password
                                    </Button>
                                </Box>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                    align="left"
                                >
                                    Didn&apos;t receive the verification code?
                                    {' '}
                                    { timer === 0 ?
                                        <Link
                                            onClick={sendVerificationCode}
                                            variant="h6"
                                            href={'#'}
                                        >
                                            Resend again
                                        </Link> :
                                        <Typography
                                            color="inherit"
                                            variant="body1"
                                            display={"inline"}
                                        >
                                            {timer / 1000}
                                        </Typography>
                                    }
                                </Typography>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
};

export default ResetPasswordView;