import React from 'react';
import { useRoutes } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from './components/GlobalStyles';
import theme from './theme';
import routes from './routes';
import {positions, Provider, transitions} from "react-alert";
import AlertTemplate from "react-alert-template-basic";
import {useSelector} from "react-redux";

const options = {
    timeout: 5000,
    position: positions.BOTTOM_CENTER,
    transition: transitions.SCALE
};

const App = () => {
    const isLoggedIn= useSelector(state => state.authenticationReducer.isAuthenticated);

    const routing = useRoutes(routes(isLoggedIn));

    return (
        <ThemeProvider theme={theme}>
            <Provider template={AlertTemplate} {...options}>
                <GlobalStyles />
                {routing}
            </Provider>
        </ThemeProvider>
    );
};

export default App;