import {
    Box, Button, ButtonGroup,
    Card,
    CardContent, CardHeader, Divider, Typography,
} from '@material-ui/core';
import PropTypes from "prop-types";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer, Sector} from "recharts";
import React, {useCallback, useState} from "react";
import { CSVLink } from "react-csv";

const COLORS = ["#0088fe", "#788efe", "#a995fb", "#cd9ef8", "#ffa5d9", "#ff8894", "#ff816c", "#ff8042"];

const style = {
    boxShadow: 10,
    borderRadius: 20
}

const renderActiveShape = (props) => {
    const {
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value
    } = props;

    return (
        <g>
            <text x={cx} y={cy} dy={0} textAnchor="middle" fill={fill}>
                { payload.language }
            </text>
            <text x={cx} y={cy} dy={20} textAnchor="middle" fill={fill}>
                { value }
            </text>
            <text x={cx} y={cy} dy={40} textAnchor="middle" fill={fill}>
                { `${(percent * 100).toFixed(2)}%` }
            </text>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
        </g>
    );
};

const DurationByLanguage = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const handleMouseEnter = useCallback(
        (o) => {
            const langArray = props.data.map(function (obj) {
                return obj.language;
            });
            setActiveIndex(langArray.indexOf(o.value));
        },
        [props.data]
    );

    const onPieEnter = useCallback(
        (_, index) => {
            setActiveIndex(index);
        },
        [setActiveIndex]
    );

    return (
        <Card
            sx={{
                height: '100%',
                boxShadow: 3,
                borderRadius: 16
            }}
            raised
            style={style}
        >
            <CardHeader title={"Total Durations By Language (min)"}
                        action={
                                <ButtonGroup variant="contained" style={{border: "solid 1px #000000"}}>
                                    <CSVLink data={props.csvData}
                                             style={{ //pass other props, like styles
                                                 background:"#87CEFA",
                                                 backgroundColor:"#87CEFA",
                                                 cursor:"pointer","color":"#000000",
                                                 padding:"6px 16px",
                                             }}
                                             filename={"total_durations_by_language.csv"}
                                    >
                                        Export CSV
                                    </CSVLink>
                                    <Button onClick={() => props.setDurationByLanguageTImeSpan('All')} color={
                                        props.durationByLanguageTImeSpan === 'All' ? "secondary" : null
                                    } style={ props.durationByLanguageTImeSpan === 'All' ? null : { backgroundColor: "#FFFFFF" }}>
                                        All
                                    </Button>
                                    <Button onClick={() => props.setDurationByLanguageTImeSpan('Month')} color={
                                        props.durationByLanguageTImeSpan === 'Month' ? "secondary" : null
                                    } style={ props.durationByLanguageTImeSpan === 'Month' ? null : { backgroundColor: "#FFFFFF" }}>
                                        Last 30 days
                                    </Button>
                                </ButtonGroup>
                        }
            />

            <Divider />
            <CardContent>
                {
                    props.data.length === 0 ?
                        <Typography variant={"h1"} align={"center"}>
                            No Data
                        </Typography>
                        :
                        <Box
                            sx={{
                                height: 500,
                            }}
                        >
                            <div style={{
                                paddingBottom: '56.25%', /* 16:9 */
                                position: 'relative',
                                height: 500
                            }}>
                                <div style={{
                                    position: 'absolute',
                                    top: '0',
                                    left: '0',
                                    width: '100%',
                                    height: '100%'
                                }}>
                                    {
                                        props.data.length !== 0 ?
                                            <ResponsiveContainer>
                                                <PieChart>
                                                    <Pie
                                                        activeIndex={activeIndex}
                                                        activeShape={renderActiveShape}
                                                        cy={'50%'}
                                                        data={props.data}
                                                        innerRadius={100}
                                                        outerRadius={120}
                                                        fill="#8884d8"
                                                        dataKey="duration"
                                                        legendType={"circle"}
                                                        onMouseEnter={onPieEnter}
                                                    >
                                                        {props.data.map((entry, index) => (
                                                            <Cell key={`cell-${index}`}
                                                                  fill={COLORS[index % COLORS.length]}
                                                                  name={entry.language}
                                                            />
                                                        ))}
                                                    </Pie>
                                                    <Legend dataKey="duration" onClick={handleMouseEnter}/>
                                                </PieChart>
                                            </ResponsiveContainer> : null
                                    }
                                </div>
                            </div>

                        </Box>
                }
            </CardContent>
        </Card>
    )
};

DurationByLanguage.propTypes = {
    data: PropTypes.array.isRequired,
    durationByLanguageTImeSpan: PropTypes.string.isRequired,
    setDurationByLanguageTImeSpan: PropTypes.func.isRequired
};

export default DurationByLanguage;