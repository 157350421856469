import React from 'react';
import PropTypes from 'prop-types';

import PlayerControls from './PlayerControl/index';
import ProgressBar from './ProgressBar';

import styles from './index.module.scss';

import {
    secondsToTimeCode,
    timeCodeToSeconds
} from '../../util/TimeCodeConverter';
import {connect} from "react-redux";


class MediaPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            timecodeOffset: this.props.timecodeOffset,
            isPlaying: false,
            previewIsDisplayed: true,
            isMute: false,
            upload: false
        };
    }

    static getDerivedStateFromProps(nextProps) {
        if (nextProps.timecodeOffset !== null) {
            let newCurrentTimeInSeconds = nextProps.timecodeOffset;

            if (
                typeof newCurrentTimeInSeconds === 'string' &&
                newCurrentTimeInSeconds.includes(':') &&
                !newCurrentTimeInSeconds.includes('NaN')
            ) {
                newCurrentTimeInSeconds = timeCodeToSeconds(nextProps.timecodeOffset);
            }

            return {
                timecodeOffset: newCurrentTimeInSeconds
            };
        }

        return null;
    }

    componentDidMount() {
        // TODO: Should these hook functions be optional? are they needed? what do they actually do?
        // TODO: these hook functions need refactoring, they are causing performance problems
        this.props.hookSeek(this.setCurrentTime);
        this.props.hookPlayMedia(this.togglePlayMedia);
        this.props.hookIsPlaying(this.isPlaying);
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.timecodeOffset !== this.state.timecodeOffset) {
            return true;
        }
        // TODO: workaround to keep the hook functions, only call re-renders
        // if current time has changed. And it seems eliminate component's unnecessary re-renders.
        if (nextProps.currentTime !== this.props.currentTime) {
            return true;
        }

        if (nextProps.mediaDuration !== this.props.mediaDuration ) {
            return true;
        }

        if (nextState.isMute !== this.state.isMute) {
            return true;
        }

        if (nextProps.exportOptions !== this.props.exportOptions){
            return true;
        }

        return false;
    }

    handleTimestampScrollIntoView = (seconds) => {
        let timestampList = this.props.timestampList;

        let closest = timestampList.reduce(function(prev, curr) {
            return (Math.abs(curr - seconds) < Math.abs(prev - seconds) ? curr : prev);
        });

        //query timeCode in WrapperBlock
        const currentWordElement = document.getElementById(
            `timeCodeElement-${closest}`
        );

        currentWordElement.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center'
        });
    }

    setCurrentTime = newCurrentTime => {
        if (newCurrentTime !== '' && newCurrentTime !== null) {
            // hh:mm:ss:ff - mm:ss - m:ss - ss - seconds number or string and hh:mm:ss
            const newCurrentTimeInSeconds = timeCodeToSeconds(newCurrentTime);
            const videoRef = this.props.videoRef.current;

            if (videoRef.readyState === 4) {
                videoRef.currentTime = newCurrentTimeInSeconds;
                if (this.props.isScrollIntoViewOn) {
                    try{
                        this.handleTimestampScrollIntoView(newCurrentTimeInSeconds);
                    }
                    catch (e){
                        console.log("Timestamp scrollview had error");
                    }

                }
                this.playMedia();
            }
        }
    };

    /**
     * Prompts for a time stamp or time code to set media current time
     * Also handles use can when the user has set a timecode offset via settings
     * and the prompt is expected to be relative to that offset
     */
    promptSetCurrentTime = () => {
        let userTimecodeValue = prompt(
            'Jump to time (format => hh:mm:ss:ff / hh:mm:ss / mm:ss / m:ss / m.ss)'
        );
        // TODO: add some validation, eg if user types just a string it won't crash.
        // needs function to check it's either timecode on the formats specified above or a number
        // this could be part of the timecode module(?)
        // user clicks cancel to prompt, prompt returns null
        if (userTimecodeValue !== null) {
            if (userTimecodeValue.includes(':')) {
                userTimecodeValue = timeCodeToSeconds(userTimecodeValue);
            }
            // remove timecode offset if preset
            if (this.state.timecodeOffset !== 0) {
                userTimecodeValue -= this.state.timecodeOffset;
            }

            this.setCurrentTime(userTimecodeValue);
        }
    };

    setTimeCodeOffset = newTimeCodeOffSet => {
        if (newTimeCodeOffSet !== '' && newTimeCodeOffSet !== null) {
            // use similar helper function from above to convert
            let newCurrentTimeInSeconds = newTimeCodeOffSet;
            if (newTimeCodeOffSet.includes(':')) {
                newCurrentTimeInSeconds = timeCodeToSeconds(newTimeCodeOffSet);
                this.setState({ timecodeOffset: newCurrentTimeInSeconds });
            }
        }
    };

    rollBack = () => {
        this.setCurrentTime(0);
    };

    handleMuteVolume = () => {
        if (this.props.videoRef.current.volume > 0) {
            this.props.videoRef.current.volume = 0;
            this.setState({ isMute: true });
            console.log("Audio is muted");
        } else {
            this.props.videoRef.current.volume = 1;
            this.setState({ isMute: false });
            console.log("Audio is unmuted");
        }
    };

    // TEMP: keeping this in for now. Might be replaced by state
    // The pauseWhileTyping logic (in TimedTextEditor) currently uses this
    isPlaying = () => {
        return !this.props.videoRef.current.paused;
    };

    pauseMedia = () => {
        this.setState({ isPlaying: false }, () => this.props.videoRef.current.pause());
    };

    playMedia = () => {
        this.setState({ isPlaying: true }, () => this.props.videoRef.current.play());
    };

    // Sets isPlaying state and toggles modes on the video player
    // TODO: modularise these / enable specific play / pause action
    togglePlayMedia = () => {
        if (this.state.isPlaying) {
            this.pauseMedia();
            console.log("Pause audio");
        }
        else {
            this.playMedia();
            console.log("Play audio");
        }
    };

    skipForward = () => {
        const currentTime = this.props.videoRef.current.currentTime;
        const newCurrentTimeIncreased = currentTime + 10;
        const newCurrentTime = Number(newCurrentTimeIncreased.toFixed(1));

        this.setCurrentTime(newCurrentTime);
        console.log("Skip forward 10 seconds");
    };

    skipBackward = () => {
        const currentTime = this.props.videoRef.current.currentTime;
        const newCurrentTimeIncreased = currentTime - 10;
        const newCurrentTime = Number(newCurrentTimeIncreased.toFixed(1));

        this.setCurrentTime(newCurrentTime);
        console.log("Skip backward 10 seconds");
    };

    handleProgressBarClick = e => {
        const time = e.target.value;
        this.setCurrentTime(time);
        console.log("Set bar time to ", time);
    };

    handleMediaEnd = () => {
        if (this.getProgressBarValue() === this.getProgressBarMax())
            this.pauseMedia();
    }

    getMediaCurrentTime = () => secondsToTimeCode(this.props.videoRef.current.currentTime + this.state.timecodeOffset);

    getProgressBarMax = () => parseInt(this.props.videoRef.current.duration);
    getProgressBarValue = () => parseInt(this.props.videoRef.current.currentTime);

    render() {
        this.handleMediaEnd();
        const progressBar = (
            <ProgressBar
                max={ this.getProgressBarMax() }
                value={ this.getProgressBarValue() }
                buttonClick={ this.handleProgressBarClick }
            />
        );

        const playerControlsSection = (

            <div className={ styles.controlsSection }>
                <PlayerControls
                    title={ this.props.title }
                    playMedia={ this.togglePlayMedia.bind(this) }
                    isPlaying={ this.state.isPlaying }
                    isMute={ this.state.isMute }
                    skipBackward={ this.skipBackward.bind(this) }
                    skipForward={ this.skipForward.bind(this) }
                    rollback={ this.rollBack }
                    currentTime={ this.getMediaCurrentTime() }
                    duration={ this.props.mediaDuration }
                    onSetCurrentTime={ '' }
                    onSetTimecodeOffset={ '' }
                    promptSetCurrentTime={ this.promptSetCurrentTime.bind(this) }
                    setTimeCodeOffset={ this.setTimeCodeOffset.bind(this) }
                    timecodeOffset={ secondsToTimeCode(this.state.timecodeOffset) }
                    handleMuteVolume={ this.handleMuteVolume.bind(this) }
                    mediaUrl={this.props.mediaUrl}
                    fileName={this.props.fileName}
                    createdAt={this.props.createdAt}
                    language={this.props.language}
                    exportOptions={this.props.exportOptions}
                    upload={this.props.upload}
                />
                {this.props.mediaUrl ? progressBar : null}
            </div>
        );

        return (
            <section>
                <div className={ styles.playerSection }>
                    {this.props.mediaUrl ? playerControlsSection : null}
                </div>
            </section>
        );
    }
}

MediaPlayer.propTypes = {
    videoRef: PropTypes.object.isRequired,
    title: PropTypes.string,
    hookSeek: PropTypes.func,
    hookPlayMedia: PropTypes.func,
    hookIsPlaying: PropTypes.func,
    mediaUrl: PropTypes.string,
    fileName: PropTypes.string,
    timecodeOffset: PropTypes.number,
    mediaDuration: PropTypes.string,
    currentTime: PropTypes.number,
    exportOptions: PropTypes.object,
    upload: PropTypes.func,
    isScrollIntoViewOn: PropTypes.bool
};

const mapStateToProps = state => ({
    timestampList: state.timeStampReducer
});

export default connect(mapStateToProps, null, null, {forwardRef: true})(MediaPlayer);