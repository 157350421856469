function Conversations(id, createdAt, name, participants, language, status, duration) {
    this.id =id;
    this.createdAt = createdAt;
    this.name = name;
    this.participants = participants;
    this.language = language;
    this.status = status;
    this.duration = duration;
}

export default Conversations;