function User(email, password, name, current_password, new_password, confirm_password, code) {
    this.email = email;
    this.password = password;
    this.name = name;
    this.currentPassword = current_password;
    this.newPassword = new_password;
    this.confirmNewPassword = confirm_password;
    this.code = code;
}

export default User;