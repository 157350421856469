import React from "react";
import TranscriptDisplay from "../../components/TranscriptDisplay";
import style from "./index.css";
import {useParams} from "react-router-dom";
import {apiProvider} from "../../services/api/utilities/Provider";
import ApiConversations from "../../services/api/ApiConversations";
import isEqual from "react-fast-compare";
import {Skeleton} from "@material-ui/lab";
import {Grid} from "@material-ui/core";

class ConversationView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            transcriptData: null,
            mediaUrl: null,
            title: "",
            fileName: "",
            isLoading: true,
            upload: false
        };

        this.transcriptEditorRef = React.createRef();
    }


    componentDidMount() {
        apiProvider.auth(sessionStorage.getItem("accessToken"));
        ApiConversations.getSingle(this.props.params.id).then(
            res => {
                console.log("Loading conversation");
                this.setState({
                    transcriptData: res,
                    fileName: this.props.params.id,
                    createdAt : res.createdAt,
                    language: res.language,
                    title: res.name,
                    mediaUrl: res.finalAudio.cloudLink,
                    upload: false,
                    isLoading: false
                });
            }
        );

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.upload !== this.state.upload){
            apiProvider.auth(sessionStorage.getItem("accessToken"));
            ApiConversations.getSingle(this.props.params.id).then(
                res => {
                    console.log("Loading conversation");
                    this.setState({
                        transcriptData: res,
                        fileName: this.props.params.id,
                        createdAt : res.createdAt,
                        language: res.language,
                        title: res.name,
                        mediaUrl: res.finalAudio.cloudLink,
                        upload: false
                    });
                }
            );
        }
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.state.isLoading !== nextState.isLoading)
            return true
        return !isEqual(this.state.upload, nextState.upload);
    }

    uploadFunction = (upload) => {
        this.setState({upload: upload})
    }

    render() {
        return (
            this.state.isLoading ?
                <div className={style.container}>
                    <Grid container direction="column" alignItems="center">
                        <Skeleton variant="text" width={"20%"} height={"50px"}/>
                        <Grid item container spacing={2} justify="center">
                            <Grid item>
                                <Skeleton variant="circle" width={40} height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton variant="circle" width={40} height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton variant="circle" width={40} height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton variant="circle" width={40} height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton variant="circle" width={40} height={40}/>
                            </Grid>
                            <Grid item>
                                <Skeleton variant="circle" width={40} height={40}/>
                            </Grid>
                        </Grid>
                        <Skeleton variant="text" width={"10%"} height={"50px"}/>
                        <Skeleton variant="text" width={"10%"} height={"50px"}/>
                        <Skeleton variant="text" width={"80%"} height={"20px"}/>
                        <Skeleton variant="text" width={"80%"} height={"300px"}/>
                    </Grid>
                </div>
                :
            <div className={style.container} >
                <TranscriptDisplay
                    transcriptData={this.state.transcriptData}
                    fileName={this.state.fileName}
                    createdAt={this.state.createdAt}
                    language={this.state.language}
                    mediaUrl={this.state.mediaUrl}
                    title={this.state.title}
                    upload={this.uploadFunction}
                    ref={this.transcriptEditorRef}
                />
            </div>
        );
    }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => (
    <ConversationView
        {...props}
        params={useParams()}
    />
);