import {
    Card,
    CardContent,
    Grid,
    Typography
} from '@material-ui/core';
import PropTypes from "prop-types";
import AnimatedIcon from "./AnimatedIcon";

const style = {
    boxShadow: 10,
    borderRadius: 20
}

const RecordingsAmount = (props) => (
    <Card
        sx={{
            height: '100%',
        }}
        style={style}
        raised
    >
        <CardContent>
            <Grid
                container
                spacing={2}
                justify="space-between"
            >
                <Grid item>
                    { props.done ?
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            SUCCESS CONVERSATIONS
                        </Typography>:
                        <Typography
                            color="textSecondary"
                            gutterBottom
                            variant="h6"
                        >
                            TOTAL CONVERSATIONS
                        </Typography>
                    }
                    <Typography
                        color="textPrimary"
                        variant="h3"
                    >
                        {props.recordingsNum}
                    </Typography>
                </Grid>
                <Grid item>
                    {props.done ? <AnimatedIcon color={"#33cc00"} /> : <AnimatedIcon />}
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

RecordingsAmount.propTypes = {
    recordingsNum: PropTypes.number,
    done: PropTypes.bool.isRequired
};

RecordingsAmount.defaultProps = {
    recordingsNum: 0
};

export default RecordingsAmount;