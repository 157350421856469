import React, {useCallback, useEffect, useState} from 'react';
import {
    Box, Card, CardActionArea, CardContent, Checkbox,
    Container, FormControlLabel, Grid, Link,
    makeStyles, Typography
} from '@material-ui/core';
import Page from '../../components/Page';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Link as RouterLink} from "react-router-dom";
import {apiProvider} from "../../services/api/utilities/Provider";
import ApiGroupUsers from "../../services/api/ApiGroupUsers";
import accessDenied from "../../images/access_denied.png";
import SearchBar from "material-ui-search-bar";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    },
    wrapIcon: {
        verticalAlign: 'middle',
        display: 'inline-flex'
    },
    image: {
        marginTop: 50,
        display: 'inline-block',
        maxWidth: '100%',
        width: 400
    }
}));

const GroupConversationsListView = () => {
    const classes = useStyles();
    const [users, setUsers] = useState([]);
    const [searchedUsers, setSearchedUsers] = useState([]);
    const [groupName, setGroupName] = useState("");
    const [searched, setSearched] = useState("");
    const [onlyManualInput, setOnlyManualInput] = useState(false);

    const loadUsers = useCallback(() => {
        if (sessionStorage.getItem('role') === 'group-admin'){
            apiProvider.auth(sessionStorage.getItem("accessToken"))
            const query = {
                params: {
                    limit: 10000,
                    sortBy: 'name',
                }
            }
            ApiGroupUsers.getAll(query).then(
                res => {
                    let usersList = res.docs.filter(function (user) {
                        return user.email !== sessionStorage.getItem("email")
                    });

                    setUsers(usersList);
                    setSearchedUsers(usersList);
                    setGroupName(res.docs[0].group)
                }
            );
        }
    }, []);

    useEffect(() => {
        loadUsers()
    }, [loadUsers]);

    const requestSearch = (searchedVal) => {
        setSearched(searchedVal)
        filterRows();
    };

    const cancelSearch = () => {
        setSearched("");
        filterRows();
    };

    const filterRows = () => {
        const filteredRows = users.filter((user) => {
            if (onlyManualInput)
                return user.name.toLowerCase().includes(searched.toLowerCase()) && user.manualTranscriptCount !== 0;
            else
                return user.name.toLowerCase().includes(searched.toLowerCase())
        });

        setSearchedUsers(filteredRows);
        console.log("Searched users list.")
    };

    useEffect(() => {
        filterRows();
    }, [ searched ]);// eslint-disable-line react-hooks/exhaustive-deps

    const manualTranscriptCheckbox = () => {
        setOnlyManualInput(!onlyManualInput)
    };

    useEffect(() => {
        filterRows();
    }, [ onlyManualInput ]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Page
            className={classes.root}
            title="Group Conversations"
        >
            { sessionStorage.getItem('role') === 'group-admin' ?
                <Container maxWidth={false}>
                    <Box display={'flex'} p={1}>
                        <Typography variant={"h1"} gutterBottom className={classes.root} display={"inline"}>
                            {groupName}
                        </Typography>
                        <Box maxWidth={500} p={2}>
                            <SearchBar
                                value={searched}
                                onChange={(searchVal) => requestSearch(searchVal)}
                                onCancelSearch={() => cancelSearch()}
                            />
                        </Box>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={onlyManualInput}
                                    onChange={manualTranscriptCheckbox}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Waiting for manual transcription only"
                        />
                    </Box>


                    <Grid container spacing={2}>
                        {searchedUsers.map((user) => (
                            <Grid item xs={12} sm={4} key={user._id}>
                                <Link
                                    component={RouterLink}
                                    to= {`/app/group/${user._id}`}
                                    variant="h6"
                                    underline={'none'}
                                >
                                    <Card raised={true}>
                                        <CardActionArea>
                                            <CardContent>
                                                <Box display="flex" component={"span"} p={2}>
                                                    <AccountCircleIcon fontSize={"large"} color={'secondary'}/>
                                                </Box>
                                                <Typography gutterBottom variant="h2" component="h2" className={classes.wrapIcon}>
                                                    {user.name}
                                                </Typography>
                                                <Typography gutterBottom variant="h5" component="h2">
                                                    Waiting for manual transcription: {user.manualTranscriptCount}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Link>
                            </Grid>
                        ))}
                    </Grid>
                </Container> :
                <Box
                    display="flex"
                    flexDirection="column"
                    height="100%"
                    justifyContent="center"
                >
                    <Container maxWidth="md">
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="h1"
                        >
                            You are not the group admin of your group. Please contact your administrator to request access.
                        </Typography>
                        <Box textAlign="center">
                            <img
                                alt="Access Denied"
                                className={classes.image}
                                src={accessDenied}
                            />
                        </Box>
                    </Container>
                </Box>
            }

        </Page>
    );
};

export default GroupConversationsListView;