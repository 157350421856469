import React from 'react';

class Header extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            transcriptData: null,
        };

        this.transcriptEditorRef = React.createRef();
    }

    // to avoid unnecessary re-renders
    shouldComponentUpdate(nextProps) {
        return nextProps !== this.props;
    }


    render() {
        const props = this.props;

        return (<>
            <nav >
                {props.mediaUrl === null ? null : props.mediaControls}
            </nav>
        </>);
    };
}

export default Header;