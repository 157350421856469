import {Box, Container, Grid, makeStyles, TextField, Typography} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import RecordingsAmount from "../../components/Dashboard/RecordingsAmount";
import RecordingsTotalDuration from "../../components/Dashboard/RecordingsTotalDuration";
import RecordingsByLanguage from "../../components/Dashboard/RecordingsByLanguage";
import DurationByLanguage from "../../components/Dashboard/DurationByLanguage";
import Page from "../../components/Page";
import React, {useCallback, useEffect, useState} from "react";
import {apiProvider} from "../../services/api/utilities/Provider";
import ApiGroupConversations from "../../services/api/ApiGroupConversations";
import conversationAmount from "../../util/DashboardData/conversationsAmount";
import conversationsByLanguage, {statusList} from "../../util/DashboardData/conversationsByLanguage";
import conversationsTotalDuration from "../../util/DashboardData/conversationsTotalDuration";
import ApiConversations from "../../services/api/ApiConversations";
import PropTypes from "prop-types";
import ApiGroupUsers from "../../services/api/ApiGroupUsers";
import {useAlert} from "react-alert";
import TimeSeries from "../../components/Dashboard/TimeSeries";
import moment from 'moment';

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: '100%',
        paddingBottom: theme.spacing(1)
    },
    welcome: {
        margin: 20,
        marginBottom: 40
    }
}));

const Dashboard = (props) => {
    const classes = useStyles();
    const alert = useAlert();
    const [ conversations, setConversations ] = useState(null);
    const [ conversationsNum, setConversationNum ] = useState("");
    const [ doneConversationsNum, setDoneConversationsNum ] = useState(null);
    const [ statuses, setStatuses ] = useState([]);
    const [ totalHours, setTotalHours ] = useState(null);
    const [ totalMinutes, setTotalMinutes ] = useState(null);
    const [ totalSeconds, setTotalSeconds ] = useState(null);
    const [ recordingsByLanguage, setRecordingsByLanguage ] = useState([]);
    const [ durationByLanguage, setDurationByLanguage ] = useState([])
    const [ showLegend, setShowLegend ] = useState(true);
    const [ timeSeriesDisplay, setTimeSeriesDisplay ] = useState(true);
    const [ users, setUsers ] = useState([]);
    const [ userFilterName, setUserFilterName ] = useState("All");
    const [ userFilterId, setUserFilterId ] = useState("All");
    const [ timeSeries, setTimeSeries ] = useState([]);
    const [ timeSpan, setTimeSpan ] = useState("day");
    const [ recordingByLanguageTImeSpan, setRecordingByLanguageTImeSpan ] = useState("All")
    const [ durationByLanguageTImeSpan, setDurationByLanguageTImeSpan ] = useState("All")
    const [ csvData, setCsvData ] = useState("")

    const loadUsers = useCallback(() => {
        const listToKeep = ['name', '_id'];
        if (sessionStorage.getItem('role') === 'group-admin'){
            apiProvider.auth(sessionStorage.getItem("accessToken"))
            const query = {
                params: {
                    limit: 10000,
                    sortBy: 'name',
                }
            }
            ApiGroupUsers.getAll(query).then(
                res => {
                    let usersList = res.docs.filter(function (user) {
                        if (user.email === sessionStorage.getItem("email")){
                            sessionStorage.setItem("id", user._id);
                        }
                        return user.email !== sessionStorage.getItem("email")
                    });

                    usersList.unshift({ name: 'All' });

                    usersList.forEach((obj)=>{
                        Object.keys(obj).forEach((key)=>{
                            if(listToKeep.indexOf(key) === -1){
                                delete obj[key];
                            }
                        });
                    });

                    setUsers(usersList);
                }
            );
        }
    }, []);

    useEffect(() => {
        setUserFilterName('All')
    }, [props.type]);

    useEffect(() => {
        loadUsers()
    }, [loadUsers]);

    const loadConversations = useCallback( async () =>  {
        let conversationList = [];
        apiProvider.auth(sessionStorage.getItem("accessToken"))
        let query;
        let csvQuery;

        const dashboardAlert  = alert.info("Loading dashboard",{timeout:20000});
        query = {
            params: {
                limit: 100000,
            }
        }

        csvQuery = {
            params: {
                user: "all"
            }
        }

        let dateStatistic;
        let timeSeriesList = [];
        if (timeSpan === "week")
            query.params.days = 7;
        else if (timeSpan === "month")
            query.params.days = 30;
        else if (timeSpan === "day"){
            query.params.days = 1;
            query.params.past24hours = true;
        }

        if (props.type === 'group'){
            if (userFilterName !== 'All' && userFilterName !== undefined){
                query.params.user = userFilterId
            }
            await ApiGroupConversations.getAll(query).then(
                res => {
                    conversationList = res.docs
                }
            );

        }
        else if (props.type === 'user'){
            await ApiConversations.getAll(query).then(
                res => {
                    conversationList = res.docs;
                }
            );
            if (sessionStorage.getItem('role') === 'group-admin'){
                query.params.user = sessionStorage.getItem("id")
            }
        }

        await ApiConversations.report(query, "time").then(
            res => {
                res.forEach((stat) => {
                    dateStatistic = {}
                    statusList(conversationList).forEach((status) => {
                        dateStatistic[status] = 0;
                    })
                    stat.languages.forEach((language) => {
                        language.statuses.forEach((status) => {
                            dateStatistic[status.status] = dateStatistic[status.status] + status.count
                        })
                    })
                    if (timeSpan === "day"){
                        dateStatistic["date"] = moment(stat._id.startTime).format("DD/MM") + " " + moment(stat._id.startTime).format("HH:mm") + "-" + moment(stat._id.startTime).add(1,'h').format("HH:mm")
                    }
                    else
                        dateStatistic["date"] = stat.date.day + "/" + stat.date.month + "/" + stat.date.year;
                    timeSeriesList.push(dateStatistic);
                })
            }
        )

        if (userFilterName !== 'All' && userFilterName !== undefined){
            csvQuery.params.user = userFilterId
        }

        if (props.type === 'user' && sessionStorage.getItem('role') === 'group-admin'){
            csvQuery.params.user = sessionStorage.getItem("id")
        }

        await ApiConversations.report(csvQuery, "csv").then(
            res => {
                setCsvData(res)
            }
        )

        setConversationNum(conversationAmount(conversationList, 'all'));
        setDoneConversationsNum(conversationAmount(conversationList, 'done'));
        
        setStatuses(statusList(conversationList));

        setTotalHours(conversationsTotalDuration(conversationList).hours);
        setTotalMinutes(conversationsTotalDuration(conversationList).minutes);
        setTotalSeconds(conversationsTotalDuration(conversationList).seconds);
        
        setRecordingsByLanguage(conversationsByLanguage(conversationList, 'amount', recordingByLanguageTImeSpan));

        setDurationByLanguage(conversationsByLanguage(conversationList, 'duration', durationByLanguageTImeSpan));
        setConversations(conversationList);

        timeSeriesList.reverse();
        setTimeSeries(timeSeriesList);
        alert.remove(dashboardAlert);
        alert.success("Dashboard is updated successfully",{timeout:2000});
        // eslint-disable-next-line
    }, [alert, userFilterId, props.type, timeSpan, recordingByLanguageTImeSpan, durationByLanguageTImeSpan]);

    useEffect(() => {
        function handleResize() {
            if (window.innerWidth < 700) {
                setTimeSeriesDisplay(false);
                setShowLegend(false);
            } else if (window.innerWidth >= 700) {
                setTimeSeriesDisplay(true);
                setShowLegend(true);
            }
        }
        window.addEventListener("resize", handleResize);
        handleResize();
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    useEffect(() => {
        loadConversations();
    }, [loadConversations]);

    return (
        <Page
            className={classes.root}
            title="Group Dashboard"
        >
            <Box
                sx={{
                    backgroundColor: 'background.default',
                    minHeight: '100%',
                    py: 3
                }}
            >
                {
                    conversations ?
                        <Container maxWidth={false}>
                            <Grid container spacing={1} alignItems={"center"} direction="row">
                                <Grid
                                    item
                                    lg={8}
                                    sm={8}
                                    xl={8}
                                    xs={12}
                                >
                                    {
                                        props.type === 'user' ?
                                            <Typography variant="h1" component="h2" paragraph className={classes.welcome}>
                                                Hi, Welcome back
                                            </Typography> :
                                            <Typography variant="h1" component="h2" paragraph className={classes.welcome}>
                                                Group Overview
                                            </Typography>
                                    }
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={4}
                                    xs={12}
                                >
                                    {
                                        props.type === 'group' ?
                                            <Autocomplete
                                                autoComplete={true}
                                                id="users-box"
                                                openText={'Choose'}
                                                disableClearable={true}
                                                options={users}
                                                getOptionLabel={(option) => option.name}
                                                onChange={(event, newValue) => {
                                                    setUserFilterName(newValue.name);
                                                    setUserFilterId(newValue._id)
                                                }}
                                                defaultValue={{ name: 'All' }}
                                                style={{ margin: 15 }}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        label="Users"
                                                        variant="outlined"
                                                    />}
                                            />: null
                                    }
                                </Grid>
                            </Grid>
                            <Grid
                                container
                                spacing={3}
                            >
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={4}
                                    xs={12}
                                >
                                    <RecordingsAmount done={false} recordingsNum={conversationsNum}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={4}
                                    xs={12}
                                >
                                    <RecordingsAmount done={true} recordingsNum={doneConversationsNum}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    sm={4}
                                    xl={4}
                                    xs={12}
                                >
                                    <RecordingsTotalDuration hours={totalHours} minutes={totalMinutes} seconds={totalSeconds} />
                                </Grid>
                                <Grid
                                    item
                                    lg={8}
                                    md={12}
                                    xl={9}
                                    xs={12}
                                >
                                    <RecordingsByLanguage data={recordingsByLanguage} statuses={statuses} showLegend={showLegend} recordingByLanguageTImeSpan={recordingByLanguageTImeSpan} setRecordingByLanguageTImeSpan={setRecordingByLanguageTImeSpan}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={4}
                                    md={6}
                                    xl={3}
                                    xs={12}
                                >
                                    <DurationByLanguage data={durationByLanguage} durationByLanguageTImeSpan={durationByLanguageTImeSpan} setDurationByLanguageTImeSpan={setDurationByLanguageTImeSpan} csvData={csvData}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={12}
                                    md={12}
                                    xl={12}
                                    xs={12}
                                >
                                    <TimeSeries data={timeSeries} statuses={statuses} timeSpan={timeSpan} setTimeSpan={setTimeSpan} timeSeriesDisplay={timeSeriesDisplay}/>
                                </Grid>
                            </Grid>
                        </Container> : null
                }
            </Box>
        </Page>
    );
}

Dashboard.prototype = {
    type: PropTypes.string.isRequired
};

export default Dashboard;