import React, {useEffect, useState} from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    Box,
    Card,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    makeStyles,
    IconButton,
    Link,
    TableContainer,
    Checkbox,
    Tooltip,
    TableSortLabel,
    lighten,
    Toolbar,
    CircularProgress,
    Paper,
    DialogTitle,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField, Menu, MenuItem
} from '@material-ui/core';
import NavigateNextOutlinedIcon from '@material-ui/icons/NavigateNextOutlined';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {Link as RouterLink} from "react-router-dom";
import {apiProvider} from "../../services/api/utilities/Provider";
import ApiConversations from "../../services/api/ApiConversations";
import { jsonEdit } from "../../util/FileDownload";
import JSZip from "jszip";
import {getBinaryContent} from "jszip-utils";
import { saveAs } from "file-saver";
import SttAdapter from "../../util/Adapters/SttJsonAdapter";
import {CompositeDecorator, convertFromRaw, convertToRaw, EditorState} from "draft-js";
import exportAdapter from "../../components/ExportAdapters";
import Word from "../../components/TimedConversation/Word";
import { useAlert } from "react-alert";
import Conversations from "../../services/model/Conversations";
import {Check, Clear, SelectAll} from "@material-ui/icons";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {DropzoneDialog} from "material-ui-dropzone";
//import streamSaver from 'streamsaver';

const useStyles = makeStyles((theme) => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2)
    },
    confirmButton: {
        color: "green"
    },
    clearAllButton: {
        color: "red"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    },
    link:{
        '&:hover': {
            color: theme.palette.primary.main
        }
    },
    [theme.breakpoints.down('xs')]:{
        fixedTableCell:{
            position: 'sticky',
            backgroundColor: theme.palette.background.default,
            left: 0,
            zIndex: 1,
        }
    },
    [theme.breakpoints.up('sm')]:{
        tablePagination: {
            marginRight: 30
        }
    },
    [theme.breakpoints.down('sm')]:{
        tablePagination: {
            marginRight: 60
        }
    },

}));

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(20),
        maxWidth: "110%",
        width: "100%"
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
}));


const headCells = [
    { id: 'name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'createdAt', numeric: false, disablePadding: false, label: 'Created At' },
    { id: 'duration', numeric: true, disablePadding: false, label: 'Duration (min:sec)' },
    { id: 'participants', numeric: true, disablePadding: false, label: 'Participants' },
    { id: 'language', numeric: false, disablePadding: true, label: 'Language' },
    { id: 'status', numeric: false, disablePadding: false, label: 'Status' },
    { id: 'actions', numeric: false, disablePadding: false, label: 'Actions' }
];

const availableStatus = [
    'done',
    'processing',
    'waiting-manual-transcription',
    'pending'
];

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'select all conversations' }}
                    />
                </TableCell>
                {headCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        sortDirection={orderBy === headCell.id ? order : false}
                        padding={headCell.label === 'Name' ? 'checkbox' : 'default'}
                        className={headCell.label === 'Name' ? classes.fixedTableCell : null}
                    >
                        { headCell.id !== 'actions' ? (<TableSortLabel
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={createSortHandler(headCell.id)}
                        >
                            <Typography
                                color="textPrimary"
                                variant="h5">
                                {headCell.label}
                            </Typography>
                            {orderBy === headCell.id ? (
                                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
                            ) : null}
                        </TableSortLabel>) : (
                            <Typography
                                color="textPrimary"
                                variant="h5">
                                {headCell.label}
                            </Typography>
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

function CircularProgressWithLabel(props) {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

CircularProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate variant.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const { numSelected, selectedIds } = props;
    const [ progress, setProgress ] = useState(0);

    let Promise = window.Promise;

    if (!Promise) {
        Promise = JSZip.external.Promise;
    }

    function urlToPromise(url) {
        return new Promise(function(resolve, reject) {
            getBinaryContent(url, function (err, data) {
                if(err) {
                    reject(err);
                } else {
                    resolve(data);
                }
            });
        });
    }

    const handleDownloads = async (ids) => {
        console.group("Multiple Files Download");
        console.time();
        setProgress(0);
        let zip = new JSZip();

        apiProvider.auth(sessionStorage.getItem("accessToken"));
        ids.forEach((id) => {
            ApiConversations.getSingle(id).then(
                res => {
                    let tmpData;
                    tmpData = jsonEdit(res);
                    zip.folder(`${res.name}-${id}`).file(`${res.name}-${id}.json`, tmpData)

                    const blocks = SttAdapter(res);
                    let contentState = convertFromRaw(blocks);
                    // eslint-disable-next-line no-use-before-define
                    let editorState = EditorState.createWithContent(contentState, decorator);

                    const txtData = exportAdapter(
                        convertToRaw(editorState.getCurrentContent()),
                        'txt'
                    );

                    if (txtData.data.toString().includes("undefined"))
                        zip.folder(`${res.name}-${id}`).file(`${res.name}-${id}.txt`, "");
                    else
                        zip.folder(`${res.name}-${id}`).file(`${res.name}-${id}.txt`, txtData.data);

                    zip.folder(`${res.name}-${id}`).file(`${res.name}-${id}.wav`, urlToPromise(res.finalAudio.cloudLink), {binary: true});
                }
            );
        })

        //TODO: should not use timeout. The timeout is to avoid creating zip folder before file creating
        await new Promise(resolve => setTimeout(resolve, 1000));

        zip.generateAsync({type: "blob"}, function updateCallback(metadata) {
            setProgress(Math.floor(metadata.percent.toFixed(2)));

            let msg = "progression : " + metadata.percent.toFixed(2) + " %";
            if (metadata.currentFile) {
                msg += ", current file = " + metadata.currentFile;
            }
            console.log(msg);
        })
            .then(function callback(blob) {

                // see FileSaver.js
                saveAs(blob, "conversations.zip");

                console.timeEnd();
                console.log("done");
                console.groupEnd();
            }, function (e) {
                console.log(e)
            });
    }

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            {numSelected > 0 ? (
                <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {numSelected} selected
                </Typography>
            ) : (
                <Typography className={classes.title} variant="h2" id="tableTitle" component="div">
                    Conversations
                </Typography>
            )}

            {numSelected > 0 ? (
                <Tooltip title="Download selected">
                    <IconButton aria-label="download" onClick={() => handleDownloads(selectedIds)}>
                        <CloudDownloadIcon/>
                    </IconButton>
                </Tooltip>
            ) : null}

            {numSelected > 0 && progress !== 0 && progress !== 100 ? (
                <CircularProgressWithLabel value={progress}/>
            ) : null}
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
};

function ConfirmationDialog(props) {
    const { confirmDialog, setConfirmDialog } = props;

    return (
        <Dialog open={confirmDialog.isOpen}>
            <DialogTitle>
                {confirmDialog.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {confirmDialog.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={confirmDialog.onConfirm}
                >
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    confirmDialog: PropTypes.object.isRequired,
    setConfirmDialog: PropTypes.func.isRequired,
};

function EditDialog(props) {
    const { editDialog, setEditDialog, handleEditDialog } = props;

    const handleTextFieldChange = (e) => {
        handleEditDialog(e.target.value);
    }

    return (
        <Dialog open={editDialog.isOpen}>
            <DialogTitle>
                {editDialog.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {editDialog.content}
                </DialogContentText>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="New Conversation Name"
                    fullWidth
                    onChange={handleTextFieldChange}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => setEditDialog({ ...editDialog, isOpen: false })}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={editDialog.onConfirm}
                >
                    Save change
                </Button>
            </DialogActions>
        </Dialog>
    );
}

EditDialog.propTypes = {
    editDialog: PropTypes.object.isRequired,
    setEditDialog: PropTypes.func.isRequired,
    handleEditDialog: PropTypes.func.isRequired
};

function ConfirmationUploadDialog(props) {
    const { confirmUploadDialog, setConfirmUploadDialog, setOpenDialog } = props;

    return (
        <Dialog open={confirmUploadDialog.isOpen}>
            <DialogTitle>
                {confirmUploadDialog.title}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {confirmUploadDialog.content}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="default"
                    onClick={() => {
                        setConfirmUploadDialog({ ...confirmUploadDialog, isOpen: false });
                        setOpenDialog(true);
                    }}
                >
                    Cancel
                </Button>
                <Button
                    color="secondary"
                    onClick={confirmUploadDialog.onConfirm}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationUploadDialog.propTypes = {
    confirmUploadDialog: PropTypes.object.isRequired,
    setConfirmUploadDialog: PropTypes.func.isRequired,
    setOpenDialog: PropTypes.func.isRequired
};

const ConversationsList = ({ className, conversations, userId, ...rest }) => {
    const classes = useStyles();
    const [conversationsList, setConversationsList] = useState( [...conversations] );
    const [selectedConversationsIds, setSelectedConversationsIds] = useState([]);
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('createdAt');
    const [limit, setLimit] = useState(sessionStorage.getItem("conversationsLimit") ? parseInt(sessionStorage.getItem("conversationsLimit"), 10) : 10);
    const [page, setPage] = useState(sessionStorage.getItem("conversationsPage") ? parseInt(sessionStorage.getItem("conversationsPage"), 10) : 0);
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', content: '' });
    const [editDialog, setEditDialog] = useState({ isOpen: false, title: '', content: '' });
    const [changedName, setChangedName] = useState("")
    const [changedId, setChangedId] = useState("")
    const [openUpload, setOpenUpload ] = useState(false)
    const [uploadConversation, setUploadConversation] = useState({ id: '' })
    const [confirmUploadDialog, setConfirmUploadDialog] = useState({ isOpen: false, title: '', content: '' });
    const alert = useAlert();

    useEffect(() => {
        setConversationsList(conversations);
    }, [conversations])

    useEffect(() => {
        sessionStorage.removeItem("conversationsLimit");
        sessionStorage.removeItem("conversationsPage");
        setLimit(10);
        setPage(0);
    }, [userId.user])

    useEffect(() => {
        if (changedName !== "" && changedId !== "" && editDialog.isOpen === false){
            apiProvider.auth(sessionStorage.getItem("accessToken"));
            let conversation = new Conversations(null, null, changedName, null, null, null);
            ApiConversations.patch(changedId, conversation).then(
                res => {
                    if (res._id !== undefined){
                        console.log("Conversation name is changed");
                        alert.success("Changed name successfully!");
                        conversationsList.forEach((conversation, index) => conversation.id === changedId ? conversationsList[index]['name'] = changedName : null);
                        setConversationsList(conversationsList);
                        setChangedName("")
                        setChangedId("")
                    }
                }
            );
        }
    }, [conversationsList, alert, changedName, editDialog, changedId])

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelecteds = conversationsList.map((conversation) => availableStatus.includes(conversation.status) ? conversation.id : null);
            newSelecteds = newSelecteds.filter((newSelected) => newSelected !== null);
            setSelectedConversationsIds(newSelecteds);
            return;
        }
        setSelectedConversationsIds([]);
    };

    const handleClick = (event, id) => {
        const selectedIndex = selectedConversationsIds.indexOf(id);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedConversationsIds, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedConversationsIds.slice(1));
        } else if (selectedIndex === selectedConversationsIds.length - 1) {
            newSelected = newSelected.concat(selectedConversationsIds.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedConversationsIds.slice(0, selectedIndex),
                selectedConversationsIds.slice(selectedIndex + 1),
            );
        }

        setSelectedConversationsIds(newSelected);
    };

    const handleLimitChange = (event) => {
        setLimit(event.target.value);
        sessionStorage.setItem("conversationsLimit", event.target.value);
        setPage(0);
    };

    const handlePageChange = (event, newPage) => {
        setPage(newPage);
        sessionStorage.setItem("conversationsPage", newPage);
    };

    const handleTotalDone = (conversationsList) => {
        let len = 0;
        conversationsList.forEach((conversation) => availableStatus.includes(conversation.status) ? len += 1 : len)
        return len;
    }

    const handleDelete = (id) => {
        setConfirmDialog({
            ...confirmDialog,
            isOpen: false
        });
        apiProvider.auth(sessionStorage.getItem("accessToken"));
        ApiConversations.remove(id).then(
            res => {
                if (res.message === "success"){
                    console.log("Deletion is performed");
                    alert.success("Deleted Successfully!");
                    setConversationsList(conversationsList.filter((conversation) => conversation.id !== id));
                }
            }
        );
    }

    const handleEditDialog = (name) => {
        setChangedName(name);
    }

    const handleModify = (id) => {
        setChangedId(id);
        setEditDialog({
            ...editDialog,
            isOpen: false
        });
    }

    const handleUpload = (file, id) => {
        setConfirmUploadDialog({
            ...confirmUploadDialog,
            isOpen: false
        });
        let ext = file.name.split('.').pop().toLowerCase()
        if (ext === 'textgrid')
            ext = 'TextGrid'
        let bodyFormData = new FormData();
        bodyFormData.append('transcript', file);
        bodyFormData.append('fileType', ext)
        ApiConversations.upload(bodyFormData, id, 'transcript').then(
            res => {
                if (res.message === "success"){
                    ApiConversations.complete(id).then(
                        res => {
                            if (res._id){
                                alert.success("Changed status to done")
                                console.log("Changed status to done")
                            }
                            else if (res.data.statusCode === 404) {
                                console.log("Conversations are not found")
                            }
                            alert.success("Uploaded and changed the transcript successfully")
                            console.log("Uploaded and changed the transcript successfully")
                        }
                    )
                }
                else{
                    alert.error("Transcript Upload Failed")
                }
            }
        )
    }

    return (
        <Card
            className={clsx(classes.root, className)}
            {...rest}
        >
            <Box>
                <EnhancedTableToolbar numSelected={selectedConversationsIds.length} selectedIds={selectedConversationsIds}/>
                <TableContainer component={Paper}>
                    <Table>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selectedConversationsIds.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={handleTotalDone(conversationsList)}
                        />
                        <TableBody>
                            {stableSort(conversationsList, getComparator(order, orderBy)).slice(page*limit, page*limit + limit).map((conversation, index) => (
                                <TableRow
                                    hover
                                    onClick={(event) => availableStatus.includes(conversation.status) ? handleClick(event, conversation.id) : null}
                                    role="checkbox"
                                    key={conversation.id}
                                    selected={selectedConversationsIds.indexOf(conversation.id) !== -1}
                                    selectable={`${availableStatus.includes(conversation.status)}`}
                                >
                                    <TableCell padding="checkbox">
                                        { availableStatus.includes(conversation.status) ?
                                            <Checkbox
                                                onClick={(event) => handleClick(event, conversation.id)}
                                                checked={selectedConversationsIds.indexOf(conversation.id) !== -1}
                                                inputProps={{ 'aria-labelledby': index }}
                                            /> : null
                                        }
                                    </TableCell>
                                    <TableCell id={index} scope="row" padding={'checkbox'} className={classes.fixedTableCell}>
                                        <Box
                                            alignItems="center"
                                            display="flex"
                                        >
                                            { availableStatus.includes(conversation.status) ? <Link
                                                    component={RouterLink}
                                                    to= {`/app/conversations/${conversation.id}`}
                                                    variant="h5"
                                                    color={'inherit'}
                                                    underline={'always'}
                                                    className={classes.link}
                                                >
                                                    <Typography
                                                        color="textPrimary"
                                                        variant="h5"
                                                    >
                                                        {conversation.name}
                                                    </Typography>
                                                </Link> :
                                                <Typography
                                                    color="textPrimary"
                                                    variant="h5"
                                                >
                                                    {conversation.name}
                                                </Typography> }
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        {moment(conversation.createdAt).format('DD/MM/YYYY HH:mm')}
                                    </TableCell>
                                    <TableCell>
                                        {Math.floor(conversation.duration / 60)}:{('0'+(((conversation.duration / 60) - Math.floor(conversation.duration / 60)) * 60).toFixed(0)).slice(-2)}
                                    </TableCell>
                                    <TableCell>
                                        {conversation.participants}
                                    </TableCell>
                                    <TableCell>
                                        {conversation.language}
                                    </TableCell>
                                    <TableCell>
                                        {conversation.status}
                                    </TableCell>
                                    <TableCell onClick={(event => event.stopPropagation())}>
                                        <Tooltip title="Delete conversation" aria-label="Delete conversation">
                                            <IconButton
                                                onClick={() => {
                                                    setConfirmDialog({
                                                        isOpen: true,
                                                        title: 'DELETE',
                                                        content: "Are you sure you want to delete this conversation?",
                                                        onConfirm: () => { handleDelete(conversation.id) }
                                                    })
                                                }}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Edit conversation name" aria-label="Edit conversation name">
                                            <IconButton
                                                onClick={() => {
                                                    setEditDialog({
                                                        isOpen: true,
                                                        title: 'MODIFY NAME',
                                                        content: "Change this conversation name in the text field below.",
                                                        onConfirm: () => { handleModify(conversation.id) }
                                                    })
                                                }}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                        </Tooltip>
                                        { availableStatus.includes(conversation.status) ?
                                            <React.Fragment>
                                                <DownloadMenu data={conversation} />
                                                { sessionStorage.getItem('role') === 'group-admin' && conversation.language === 'manual-english' ?
                                                <Tooltip title="Upload TextGrid" aria-label="Upload TextGrid">
                                                    <IconButton className={ classes.playerButton }
                                                                aria-label="Upload transcript"
                                                                onClick={() => {
                                                                    setOpenUpload(true)
                                                                    setUploadConversation({id: conversation.id})
                                                                }}>
                                                        <CloudUploadIcon />
                                                    </IconButton>
                                                </Tooltip> : null }
                                                <Link
                                                    component={RouterLink}
                                                    to= {`/app/conversations/${conversation.id}`}
                                                    variant="h6"
                                                >
                                                    <IconButton disabled={!availableStatus.includes(conversation.status)} color="inherit">
                                                        <NavigateNextOutlinedIcon />
                                                    </IconButton>
                                                </Link>
                                            </React.Fragment>
                                            : null
                                        }
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <TablePagination
                className={classes.tablePagination}
                component="div"
                count={conversationsList.length}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25]}
            />
            <DropzoneDialog
                acceptedFiles={['.TextGrid, .srt']}
                cancelButtonText={"Cancel"}
                submitButtonText={"Submit"}
                filesLimit={1}
                open={openUpload}
                onClose={() => setOpenUpload(false)}
                onSave={(files) => {
                    setConfirmUploadDialog({
                        isOpen: true,
                        title: 'SUBMIT',
                        content: "Are you sure you want to upload this TextGrid/srt file?",
                        onConfirm: () => { handleUpload(files[0], uploadConversation.id); }
                    })
                    setOpenUpload(false)
                }}
                showPreviews={true}
                showFileNamesInPreview={true}
            />
            <ConfirmationDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
            <ConfirmationUploadDialog
                confirmUploadDialog={confirmUploadDialog}
                setConfirmUploadDialog={setConfirmUploadDialog}
                setOpenDialog={setOpenUpload}
            />
            <EditDialog
                editDialog={editDialog}
                setEditDialog={setEditDialog}
                handleEditDialog={handleEditDialog}
            />
        </Card>
    );
};

const getEntityStrategy = mutability => (
    contentBlock,
    callback,
    contentState
) => {
    contentBlock.findEntityRanges(character => {
        const entityKey = character.getEntity();
        if (entityKey === null) {
            return false;
        }

        return contentState.getEntity(entityKey).getMutability() === mutability;
    }, callback);
};

const decorator = new CompositeDecorator([
    {
        strategy: getEntityStrategy('MUTABLE'),
        component: Word
    }
]);

const handleNormalDownload = (id, name, alert, normalAlert) => {
    let tmpData;
    apiProvider.auth(sessionStorage.getItem("accessToken"));
    ApiConversations.getSingle(id).then(
        res => {
            getBinaryContent(res.finalAudio.cloudLink, function (err, data) {
                if (err) {
                    console.log(err); // or handle the error
                }
                let zip = new JSZip();
                let folder = zip.folder(id);
                folder.file(`${name}-${id}.wav`, data, {binary: true});

                tmpData = jsonEdit(res);
                folder.file(`${name}-${id}.json`, tmpData)

                const blocks = SttAdapter(res);
                let contentState = convertFromRaw(blocks);
                // eslint-disable-next-line no-use-before-define
                let editorState = EditorState.createWithContent(contentState, decorator);

                const txtData = exportAdapter(
                    convertToRaw(editorState.getCurrentContent()),
                    'txt'
                );
                folder.file(`${name}-${id}.txt`, txtData.data)

                zip.generateAsync({type: "blob"}).then(function (blob) {
                    saveAs(blob, `${name}-${id}.zip`);
                    alert.remove(normalAlert);
                }, function (err) {
                    console.log(err);
                });
            });
        }
    );
}

const DownloadMenu = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [download, setDownload] = React.useState({
        normal: false,
        textGrid: false,
    });
    const alert = useAlert();

    const handleSelectAll = () => {
        setDownload({ ...download, normal: true, textGrid: true });
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSelect = (event) => {
        setDownload({ ...download, [event.target.name]: event.target.checked });
    };

    const handleClearAll = () => {
        setDownload({ ...download, normal: false, textGrid: false });
    };

    const handleDownload = (conversation) => {
        if (download.normal || download.textGrid){
            if (download.normal){
                const normalAlert = alert.info("Downloading normal conversation",{timeout:600000});
                handleNormalDownload(conversation.id, conversation.name, alert, normalAlert);
            }
            if (download.textGrid) {
                const textGridAlert = alert.info("Downloading TextGrid conversation",{timeout:600000});
                ApiConversations.download(conversation.id).then(
                    res => {
                        /*
                        const fileStream = streamSaver.createWriteStream(`${conversation.name}-${conversation.id}-${moment(conversation.createdAt).format('DDMMYYYY')}.zip`, {
                            size: res.size // Makes the percentage visible in the download
                        })

                        const readableStream = res.stream()

                        // more optimized pipe version
                        // (Safari may have pipeTo but it's useless without the WritableStream)
                        if (window.WritableStream && readableStream.pipeTo) {
                            return readableStream.pipeTo(fileStream)
                                .then(() => console.log('Done writing download'))
                        }

                        // Write (pipe) manually
                        window.writer = fileStream.getWriter()

                        const reader = readableStream.getReader()
                        const pump = () => reader.read()
                            .then(res => res.done
                                ? window.writer.close()
                                : window.writer.write(res.value).then(pump))

                        pump();

                         */
                        saveAs(res, `${conversation.name}-${conversation.id}-${moment(conversation.createdAt).format('DDMMYYYY')}.zip`)
                        alert.remove(textGridAlert);
                    }
                )
            }
        }
    }

    const handleClose = () => {
        setDownload({ ...download, normal: false, textGrid: false });
        setAnchorEl(null);
    };

    const {data} = props;

    return(
        <React.Fragment>
            <IconButton
                aria-label="download"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
            >
                <CloudDownloadIcon />
            </IconButton>
            <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                elevation={2}
                anchorOrigin={{ vertical: 'top', horizontal: 'right'}}
                PaperProps={{
                    style: {
                        maxHeight: 200,
                        width: '20ch',
                    },
                }}
            >
                <MenuItem key={"normal"} divider={true}>
                    <Checkbox
                        name={"normal"}
                        checked={download.normal}
                        onChange={handleSelect}
                    />
                    Normal
                </MenuItem>
                <MenuItem key={"textGrid"} divider={true}>
                    <Checkbox
                        name={"textGrid"}
                        checked={download.textGrid}
                        onChange={handleSelect}
                    />
                    TextGrid
                </MenuItem>
                <MenuItem key="actions">
                    <Tooltip title="Select all">
                        <IconButton
                            aria-label="select-all"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleSelectAll}
                        >
                            <SelectAll color={"primary"}/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Clear all">
                        <IconButton
                            aria-label="download"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={handleClearAll}
                        >
                            <Clear className={ classes.clearAllButton }/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Confirm">
                        <IconButton
                            aria-label="confirm"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            onClick={() => handleDownload(data)}
                        >
                            <Check className={ classes.confirmButton }/>
                        </IconButton>
                    </Tooltip>
                </MenuItem>
            </Menu>
        </React.Fragment>
    )
}

ConversationsList.propTypes = {
    className: PropTypes.string,
    conversations: PropTypes.array.isRequired
};

export default ConversationsList;