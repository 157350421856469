import moment from "moment";

const statusList = (conversations) => {
    const statusList = [...new Set(conversations.map(function (obj) {
        return obj.status;
    }))];

    return statusList.sort();
}

const conversationByLanguage = (conversations, config, timespan) => {
    let recordingsLanguage={};
    let recordingsLanguageList=[];
    let durationLanguage={};
    let durationLanguageList=[];

    if (timespan === 'Month'){
        conversations = conversations.filter(function (obj) {
            return moment(obj.createdAt).isBetween(moment().subtract(30, 'days'), moment(), 'day', []);
        })
    }
    const languageList = [...new Set(conversations.map(function (obj) {
        return obj.language;
    }))];

    if ( config === 'amount'){
        languageList.forEach((language) => {
            recordingsLanguage["language"] = language;
            statusList(conversations).forEach((status) => {
                recordingsLanguage[status] = conversations.filter((conversation) => conversation.status === status && conversation.language === language).length;
            })
            recordingsLanguageList.push(recordingsLanguage);
            recordingsLanguage = {};
        })

        return recordingsLanguageList;
    }

    else if ( config === 'duration' ){
        languageList.forEach((language) => {
            durationLanguage.language = language;
            durationLanguage.duration = Math.ceil(conversations.filter((conversation) => conversation.language === language).reduce(function (previous, current) {
                if (!previous.finalAudio)
                    return previous + current.finalAudio.duration;
                return previous.finalAudio.duration + current.finalAudio.duration;
            }, 0) / 60);
            if (durationLanguage.duration !== 0){
                durationLanguageList.push(durationLanguage);
            }
            durationLanguage = {};
        })

        return durationLanguageList;
    }
}

export default conversationByLanguage;
export { statusList };