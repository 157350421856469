import React, {PureComponent} from "react";
import {
    Box, Button, ButtonGroup,
    Card, CardContent, CardHeader, Divider, Typography
} from '@material-ui/core';
import PropTypes from "prop-types";
import {Bar, Legend, BarChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";

const COLORS = ["#0088fe", "#8493ff", "#baa0ff", "#e1b1fe", "#ffc5ff", "#ffabdf", "#ff94b1", "#ff847c", "#ff8042"];

const style = {
    boxShadow: 10,
    borderRadius: 20
}

class CustomizedAxisTick extends PureComponent {
    render() {
        // eslint-disable-next-line no-unused-vars
        const { x, y, stroke, payload } = this.props;

        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={4} textAnchor="end" fill="#666" transform="rotate(-45)">
                    {payload.value}
                </text>
            </g>
        );
    }
}

const RecordingsByLanguage = (props) => (
    <Card
        sx={{
            height: '100%',
            boxShadow: 3,
            borderRadius: 16
        }}
        raised
        style={style}
    >
        <CardHeader title={"Total Conversations By Language"}
                    action={
                        <ButtonGroup variant="contained" style={{border: "solid 1px #000000"}}>
                            <Button onClick={() => props.setRecordingByLanguageTImeSpan('All')} color={
                                props.recordingByLanguageTImeSpan === 'All' ? "secondary" : null
                            } style={ props.recordingByLanguageTImeSpan === 'All' ? null : { backgroundColor: "#FFFFFF" }}>
                                All
                            </Button>
                            <Button onClick={() => props.setRecordingByLanguageTImeSpan('Month')} color={
                                props.recordingByLanguageTImeSpan === 'Month' ? "secondary" : null
                            } style={ props.recordingByLanguageTImeSpan === 'Month' ? null : { backgroundColor: "#FFFFFF" }}>
                                Last 30 days
                            </Button>
                        </ButtonGroup>
                    }
        />
        <Divider />
        <CardContent>
            {
                props.data.length === 0 ?
                    <Typography variant={"h1"} align={"center"}>
                        No Data
                    </Typography>
                    :
                    <Box
                        sx={{
                            height: 500,
                            position: 'relative'
                        }}
                    >
                        <div style={{
                            paddingBottom: '56.25%', /* 16:9 */
                            position: 'relative',
                            height: 500
                        }}>
                            <div style={{
                                position: 'absolute',
                                top: '0',
                                left: '0',
                                width: '102%',
                                height: '100%'
                            }}>
                                {
                                    props.data.length !== 0 ?
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={props.data}
                                                margin={{
                                                    top: 20,
                                                    right: 10,
                                                    bottom: 5
                                                }}
                                            >
                                                <XAxis dataKey="language" interval={0} height={150}
                                                       tick={<CustomizedAxisTick/>}/>
                                                <YAxis tickCount={5} allowDecimals={false}/>
                                                <Tooltip/>
                                                {props.showLegend ? <Legend verticalAlign="top" align="right"
                                                                            layout="vertical"/> : null}
                                                {props.statuses.map((status, index) => (
                                                    <Bar key={status} dataKey={status} stackId="a"
                                                         fill={COLORS[index % COLORS.length]} legendType={"circle"}/>
                                                ))
                                                }
                                            </BarChart>
                                        </ResponsiveContainer> : null
                                }
                            </div>
                        </div>
                    </Box>
            }
        </CardContent>
    </Card>
);

RecordingsByLanguage.propTypes = {
    data: PropTypes.array.isRequired,
    statuses: PropTypes.array.isRequired,
    showLegend: PropTypes.bool,
    recordingByLanguageTImeSpan: PropTypes.string.isRequired,
    setRecordingByLanguageTImeSpan: PropTypes.func.isRequired
};

RecordingsByLanguage.defaultProps = {
    showLegend : false
}

export default RecordingsByLanguage;