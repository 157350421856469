import {ApiCore} from "./utilities/Core";

const url = 'groups';

const ApiGroupInformation = new ApiCore({
    getSingle: true,
    getAll: false,
    patch: false,
    post: false,
    remove: false,
    url: url,
});

export default ApiGroupInformation;