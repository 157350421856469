import createEntityMap from "./createEntityMap";
import ConversationToDraft from "./ConversationToDraft";

const sttJsonAdapter = (transcriptData) => {
    let blocks;
    blocks = ConversationToDraft(transcriptData);

    return { blocks, entityMap: createEntityMap(blocks) };
};

export default sttJsonAdapter;
export { createEntityMap };