import {ApiCore} from "./utilities/Core";

const url = 'auth/login';

// plural and single may be used for message logic if needed in the ApiCore class.

const ApiLogin = new ApiCore({
    post: true,
    url: url,
});

export default ApiLogin;