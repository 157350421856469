import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
    Box,
    Divider,
    Drawer,
    Hidden,
    List,
    Typography,
    makeStyles
} from '@material-ui/core';
import {
    MessageCircle as ConversationsIcon,
    Settings as SettingsIcon,
    Users as UsersIcon,
    PieChart as PieChartIcon,
    BarChart as BarchartIcon
} from 'react-feather';
import NavItem from './DrawerItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const items = [
    {
        href: '/app/dashboard/user',
        icon: BarchartIcon,
        title: 'My Dashboard'
    },
    {
        href: '/app/dashboard/group',
        icon: PieChartIcon,
        title: 'Group Dashboard'
    },
    {
        href: '/app/conversations',
        icon: ConversationsIcon,
        title: 'Conversations'
    },
    {
        href: '/app/group',
        icon: UsersIcon,
        title: 'Group Conversations'
    },
    {
        href: '/app/settings',
        icon: SettingsIcon,
        title: 'Settings'
    }
];

const normalUserItems = [
    {
        href: '/app/dashboard/user',
        icon: BarchartIcon,
        title: 'My Dashboard'
    },
    {
        href: '/app/conversations',
        icon: ConversationsIcon,
        title: 'Conversations'
    },
    {
        href: '/app/group',
        icon: UsersIcon,
        title: 'Group Conversations'
    },
    {
        href: '/app/settings',
        icon: SettingsIcon,
        title: 'Settings'
    }
];

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256
    },
    desktopDrawer: {
        width: 256,
        top: 80,
        height: 'calc(100% - 80px)'
    },
    avatar: {
        cursor: 'pointer',
        width: 64,
        height: 80
    }
}), {index: 1});

const NavDrawer = ({ onMobileClose, openMobile }) => {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname]);

    const content = (
        <Box
            height="100%"
            display="flex"
            flexDirection="column"
        >
            <Box
                alignItems="center"
                display="flex"
                flexDirection="column"
                p={2}
            >
                <AccountCircleIcon style={{fontSize: '100px'}}/>
                <Typography
                    className={classes.name}
                    color="textPrimary"
                    variant="h5"
                >
                    {sessionStorage.getItem("email")}
                </Typography>
            </Box>
            <Divider />
            <Box p={2}>
                <List>
                    {
                        sessionStorage.getItem('role') === 'group-admin' ?
                            items.map((item) => (
                                <NavItem
                                    href={item.href}
                                    key={item.title}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            )) :
                            normalUserItems.map((item) => (
                                <NavItem
                                    href={item.href}
                                    key={item.title}
                                    title={item.title}
                                    icon={item.icon}
                                />
                            ))
                    }

                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    );

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden mdDown>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.desktopDrawer }}
                    open
                    variant="persistent"
                >
                    {content}
                </Drawer>
            </Hidden>
        </>
    );
};

NavDrawer.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool
};

NavDrawer.defaultProps = {
    onMobileClose: () => {},
    openMobile: false
};

export default NavDrawer;