import {ApiCore} from "./utilities/Core";

const url = 'auth/forgot-password';

// plural and single may be used for message logic if needed in the ApiCore class.

const ApiForgotPassword = new ApiCore({
    post: true,
    url: url,
    gateway : true
});

export default ApiForgotPassword;