import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles, Grid
} from '@material-ui/core';
import Page from '../../components/Page';
import ApiLogin from "../../services/api/ApiLogin";
import User from "../../services/model/User";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";


const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const LoginView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const alert = useAlert();
    const dispatch = useDispatch();

    return (
        <Page
            className={classes.root}
            title="Login"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                            password: Yup.string().max(255).required('Password is required')
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            let user = new User(values.email, values.password);

                            ApiLogin.post(user).then(
                                res => {
                                    if(res.accessToken){
                                        sessionStorage['accessToken'] = res.accessToken;
                                        sessionStorage['email'] = values.email;
                                        sessionStorage['role'] = res.role;
                                        console.log("Login Successfully");
                                        dispatch({ type: "SET_AUTHENTICATION_STATUS", payload: true });
                                        navigate('/app/dashboard/user', { replace: true });
                                    }
                                    else{
                                        console.log("Login Failed")
                                        alert.error("Invalid email or password");
                                    }
                                }
                                )
                            setSubmitting(false)
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Sign in
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Sign in the Meadow9 webapp
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Login
                                    </Button>
                                </Box>
                                <Grid container justify="space-between">
                                    <Typography
                                        color="textSecondary"
                                        variant="body1"
                                        align="left"
                                    >
                                        Don&apos;t have an account?
                                        {' '}

                                        <Link
                                            component={RouterLink}
                                            to="/register"
                                            variant="h6"
                                        >
                                            Register
                                        </Link>
                                    </Typography>
                                    <Typography
                                        align="right"
                                    >
                                        <Link
                                            component={RouterLink}
                                            to="/forgot_password"
                                            variant="h6"
                                        >
                                            Forgot password?
                                        </Link>
                                    </Typography>
                                </Grid>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
};

export default LoginView;