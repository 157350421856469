import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import PersonIcon from '@material-ui/icons/Person';

import style from './WrapperBlock.module.css';
import {Box, Typography} from "@material-ui/core";

class SpeakerLabel extends PureComponent {
    render() {
        return (
            <span className={ [style.speaker, style.speakerNotEditable].join(' ')}
                  title={ this.props.name }
                  onClick={ null } >
                <Box display="flex">
                    <PersonIcon />
                    <Typography display={'inline'}>
                        {this.props.name}
                    </Typography>
                </Box>
            </span>
        );
    }
}

SpeakerLabel.propTypes = {
    name: PropTypes.string,
};

export default SpeakerLabel;