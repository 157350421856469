// eslint-disable-next-line import/no-anonymous-default-export
import { shortTimeCode } from '../../../util/TimeCodeConverter';

// eslint-disable-next-line import/no-anonymous-default-export
export default (blockData) => {
    const lines = blockData.blocks.map((block) => {
        return `${ block.data.speaker } \t [${ shortTimeCode(block.data.start) }] \t ${ block.text }`;
    });

    return lines.join('\n\n');
};