import React from 'react';
import PropTypes from 'prop-types';
import {FormControl, InputLabel, makeStyles, Select} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        color: "#1E90FF",
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 130,
        color: "#1E90FF"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    select: {
        "&:before": {
            borderColor: "#1E90FF",
        },
        "&:after": {
            borderColor: "#1E90FF",
        }
    }
}));


const ExportFormatSelect = props => {
    const classes = useStyles();

    return <FormControl className={classes.formControl} color={"primary"}>
        <InputLabel htmlFor="export-native-simple" className={classes.root}>Export Format</InputLabel>
        <Select
            native
            className={ classes.select }
            name={ props.name }
            value={ props.value }
            onChange={ props.handleChange }
            inputProps={{
                name: 'Export Format',
                id: 'export-native-simple',
            }}
        >
            <option aria-label="None" value="" />
            <option value={"txt"}>Text file</option>
            <option value={"json"}>Json</option>
        </Select>
    </FormControl>
};

ExportFormatSelect.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    value: PropTypes.string,
    handleChange: PropTypes.func
};

export default ExportFormatSelect;