import React from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import User from "../../services/model/User";
import {positions, useAlert} from "react-alert";
import ApiChangePassword from "../../services/api/ApiChangePassword";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const AccountSettingView = () => {
    const classes = useStyles();
    const alert = useAlert();

    return (
        <Page
            className={classes.root}
            title="Account"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={Yup.object().shape({
                            current_password: Yup.string().max(255).required('Password is required'),
                            new_password: Yup.string().max(255).required('Password is required'),
                            confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'New passwords must match')
                        })}
                        onSubmit={(values, {setSubmitting, resetForm}) => {
                            let user = new User(sessionStorage.getItem("email"), null, null, values.current_password, values.new_password, values.confirm_password);
                            ApiChangePassword.post(user).then(
                                res => {
                                    if (res.data === undefined){
                                        alert.success(res.message);
                                        resetForm({values: ''})
                                        console.log("Changed password successfully");
                                    }
                                    else if (res.data.error){
                                        if(Array.isArray(res.data.message)){
                                            res.data.message.forEach((message) => {
                                                console.log(message)
                                                alert.error(message, {timeout:30000, position: positions.BOTTOM_RIGHT});
                                            })
                                        }
                                        else{
                                            console.log(res.data.message);
                                            alert.error(res.data.message, {timeout:10000});
                                        }
                                    }
                                    else {
                                        console.log(res.message);
                                        alert.success(res.message);
                                    }
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Change your password
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.current_password && errors.current_password)}
                                    fullWidth
                                    helperText={touched.current_password && errors.current_password}
                                    label="Current Password"
                                    margin="normal"
                                    name="current_password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.current_password || ''}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.new_password && errors.new_password)}
                                    fullWidth
                                    helperText={touched.new_password && errors.new_password}
                                    label="New Password"
                                    margin="normal"
                                    name="new_password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.new_password || ''}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.confirm_password && errors.confirm_password)}
                                    fullWidth
                                    helperText={touched.confirm_password && errors.confirm_password}
                                    label="Confirm Password"
                                    margin="normal"
                                    name="confirm_password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.confirm_password || ''}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Change Password
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
};

export default AccountSettingView;