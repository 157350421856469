import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    TextField,
    Typography,
    makeStyles,
    Tooltip
} from '@material-ui/core';
import Page from '../../components/Page';
import User from "../../services/model/User";
import { useAlert } from "react-alert";
import ApiForgotPassword from "../../services/api/ApiForgotPassword";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const ForgotPasswordView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const alert = useAlert();

    return (
        <Page
            className={classes.root}
            title="Login"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                        })}
                        onSubmit={(values, {setSubmitting}) => {
                            let user = new User(values.email);
                            ApiForgotPassword.post(user).then(
                                res => {
                                    if(res.message){
                                        alert.success(res.message);
                                        sessionStorage['resetEmail'] = values.email;
                                        navigate('/reset_password', { replace: true });
                                    }
                                    else{
                                        alert.error(res.data.message);
                                    }
                                    setSubmitting(false);
                                })
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => (
                              <>
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Forgot Password
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Enter the email address associated with your account and we will send you a verification code to reset your password.
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Send verification email
                                    </Button>
                                </Box>
                                </form>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    justifyContent: 'flex-end',
                                }}>
                                <Tooltip title="Enter email to proceed">
                                <div>
                                <Button
                                    disabled={ (values.email.length === 0) || Boolean(errors.email) }
                                    onClick={() => {
                                        sessionStorage['resetEmail'] = values.email;
                                        navigate('/reset_password', { replace: true });
                                        console.log(errors)
                                        console.log(touched)
                                    }}
                                >
                                    Already have a code?
                                </Button>
                                </div>
                                </Tooltip>
                                </div>
                            </>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
};

export default ForgotPasswordView;