const normalisePlayerTime = function (seconds, fps) {
    return Number((1.0 / fps * Math.floor(Number((fps * seconds).toPrecision(12)))).toFixed(2));
};

const SecondsToTimecode = function (seconds, framePerSeconds) {
    let fps = 25;
    if (framePerSeconds !== undefined) {
        fps = framePerSeconds;
    }

    const normalisedSeconds = normalisePlayerTime(seconds, fps);

    const wholeSeconds = Math.floor(normalisedSeconds);
    const frames = ((normalisedSeconds - wholeSeconds) * fps).toFixed(2);
    
    function _padZero(n) {
        if (n < 10) return `0${ parseInt(n) }`;

        return parseInt(n);
    }

    return `${ _padZero((wholeSeconds / 60 / 60) % 60)
    }:${
        _padZero((wholeSeconds / 60) % 60)
    }:${
        _padZero(wholeSeconds % 60)
    }:${
        _padZero(frames) }`;
};

export default SecondsToTimecode;