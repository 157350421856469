import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
    Box,
    Button,
    Container,
    Link,
    TextField,
    Typography,
    makeStyles
} from '@material-ui/core';
import Page from '../../components/Page';
import User from "../../services/model/User";
import {positions, useAlert} from "react-alert";
import ApiRegister from "../../services/api/ApiRegister";

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: '100%',
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3)
    }
}));

const RegisterView = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const alert = useAlert();

    return (
        <Page
            className={classes.root}
            title="Register"
        >
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                justifyContent="center"
            >
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: '',
                            name: '',
                            password: '',
                        }}
                        validationSchema={
                            Yup.object().shape({
                                email: Yup.string().email('Must be a valid email').max(255).required('Email is required'),
                                name: Yup.string().max(255).required('Name is required'),
                                password: Yup.string().max(255).required('Password is required'),
                                passwordConfirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match')
                            })
                        }
                        onSubmit={(values, { setSubmitting }) => {
                            let user = new User(values.email, values.password, values.name);
                            ApiRegister.post(user).then(
                                res => {
                                    if(res._id){
                                        navigate('/login', { replace: true });
                                        console.log("Register is success");
                                        alert.success("Account is created successfully");
                                    }
                                    else{
                                        if(Array.isArray(res.data.message)){
                                            res.data.message.forEach((message) => {
                                                alert.error(message, {timeout:30000, position: positions.BOTTOM_RIGHT});
                                            })
                                            console.log("Register is fail");
                                        }

                                        else{
                                            console.log("Register is fail");
                                            alert.error(res.data.message, {timeout:10000});
                                        }

                                    }
                                    setSubmitting(false)
                                }
                            )
                        }}
                    >
                        {({
                              errors,
                              handleBlur,
                              handleChange,
                              handleSubmit,
                              isSubmitting,
                              touched,
                              values
                          }) => (
                            <form onSubmit={handleSubmit}>
                                <Box mb={3}>
                                    <Typography
                                        color="textPrimary"
                                        variant="h2"
                                    >
                                        Create new account
                                    </Typography>
                                    <Typography
                                        color="textSecondary"
                                        gutterBottom
                                        variant="body2"
                                    >
                                        Use your email to create new account
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.name && errors.name)}
                                    fullWidth
                                    helperText={touched.name && errors.name}
                                    label="Name"
                                    margin="normal"
                                    name="name"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.name}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                />
                                <TextField
                                    error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                                    fullWidth
                                    helperText={touched.passwordConfirmation && errors.passwordConfirmation}
                                    label="Confirm Password"
                                    margin="normal"
                                    name="passwordConfirmation"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.passwordConfirmation}
                                    variant="outlined"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                    >
                                        Register
                                    </Button>
                                </Box>
                                <Typography
                                    color="textSecondary"
                                    variant="body1"
                                >
                                    Have an account?
                                    {' '}
                                    <Link
                                        component={RouterLink}
                                        to="/login"
                                        variant="h6"
                                    >
                                        Login
                                    </Link>
                                </Typography>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    );
};

export default RegisterView;